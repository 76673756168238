import React, { ReactNode, useContext } from 'react'
import {
  CREATE_WORKSPACE_POST,
  EDIT_WORKSPACE_PUT,
  DELETE_WORKSPACE_DEL,
  LIST_ACTIVES_WORKSPACES_GET,
  LIST_ALL_WORKSPACES_GET
} from './API/Workspace'
import { useNavigate } from 'react-router-dom'
import { WorkspaceProps } from './Types/WorkspaceType'

interface ContextValue {
  workspaces: Array<any>
  activeWorkspaces: Array<any>
  currentWorkspace: any
  setCurrentWorkspace: any
  loading: boolean
  errorMessage: string
  open: boolean
  setOpen: any
  severity: string
  setSeverity: any
  feedbackMessage: string
  setFeedbackMessage: any
  createWorkspace: (token: string | null, formData: any) => void
  editWorkspace: (token: string | null, id: number, formData: any) => void
  deleteWorkspace: (token: string | null, id: number) => void
  listActivesWorkspaces: (token: string | null) => void
  listAllWorkspaces: (token: string | null) => void
  startWorkspaceEdit: (workspace: NewWorkspace) => void
}

type NewWorkspace = {
  name: string
  file: any
  active: boolean
}

interface Props {
  children: ReactNode
}

export const WorkspaceContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
)

export const WorkspaceContextProvider = ({ children }: Props) => {
  const [workspaces, setWorkspaces] = React.useState([''])
  const [activeWorkspaces, setActiveWorkspaces] = React.useState([''])
  const [currentWorkspace, setCurrentWorkspace] = React.useState({})
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()
  //snackbar
  const [open, setOpen] = React.useState<boolean>(false)
  const [severity, setSeverity] = React.useState<string>('')
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('')

  async function createWorkspace(token: string | null, formData: any) {
    try {
      setLoading(true)
      const { url, options } = CREATE_WORKSPACE_POST(token, formData)
      const response = await fetch(url, options)
      const json = await response.json()
      if (response.ok) {
        setSeverity('success')
        setFeedbackMessage('Relatório criado com sucesso!')
        setOpen(true)
        listActivesWorkspaces(token)
        setTimeout(() => {
          navigate('/home/configuracoes/relatorios')
        }, 2000)
      } else {
        throw new Error(`${json.status} - ${json.error}`)
      }
    } catch (err: any) {
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function editWorkspace(
    token: string | null,
    id: number,
    formData: any
  ) {
    try {
      const { url, options } = await EDIT_WORKSPACE_PUT(token, id, formData)
      const response = await fetch(url, options)
      const json = await response.json()
      if (response.ok) {
        setSeverity('success')
        setFeedbackMessage('Relatório atualizado com sucesso!')
        setOpen(true)
        listActivesWorkspaces(token)
        setTimeout(() => {
          navigate('/home/configuracoes/relatorios')
        }, 2000)
      } else {
        throw new Error(`${json.status} - ${json.error}`)
      }
    } catch (err) {
      setErrorMessage('Não foi possível editar o relatório, tente novamente.')
    }
  }

  async function deleteWorkspace(token: string | null, id: number) {
    try {
      const { url, options } = DELETE_WORKSPACE_DEL(token, id)
      const response = await fetch(url, options)
      const json = await response.json()
      if (response.ok) {
        setSeverity('success')
        setFeedbackMessage(json.message)
        setOpen(true)
        listAllWorkspaces(token)
        listActivesWorkspaces(token)
      } else {
        throw new Error(`${response.status}`)
      }
    } catch (err) {
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    }
  }

  async function listActivesWorkspaces(token: string | null) {
    try {
      const { url, options } = LIST_ACTIVES_WORKSPACES_GET(token)
      const response = await fetch(url, options)
      if (!response.ok) throw new Error(response.statusText)
      const json = await response.json()
      setActiveWorkspaces(json)
    } catch (err) {
      setErrorMessage('Erro ao receber os relatórios ativos.')
    }
  }

  async function listAllWorkspaces(token: string | null) {
    try {
      const { url, options } = LIST_ALL_WORKSPACES_GET(token)
      const response = await fetch(url, options)
      if (!response.ok) throw new Error(response.statusText)
      const json = await response.json()
      setWorkspaces(json)
    } catch (err) {
      setErrorMessage('Erro ao receber os relatórios.')
    }
  }

  function startWorkspaceEdit(workspace: NewWorkspace) {
    setCurrentWorkspace({
      workspaceData: workspace,
      workspace: workspace,
      formFields: {
        name: workspace.name,
        imageUrl: workspace.file,
        active: workspace.active
      }
    })
  }

  const value = {
    createWorkspace,
    editWorkspace,
    deleteWorkspace,
    listActivesWorkspaces,
    listAllWorkspaces,
    activeWorkspaces,
    workspaces,
    loading,
    open,
    setOpen,
    severity,
    setSeverity,
    feedbackMessage,
    setFeedbackMessage,
    errorMessage,
    currentWorkspace,
    setCurrentWorkspace,
    startWorkspaceEdit
  }

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  )
}

export function useWorkspaceContext() {
  const context = useContext(WorkspaceContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useWorkspaceContext must be used within an WorkspaceContext'
    )
  }

  return context
}

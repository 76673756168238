import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './components/pages/Login/index'
import Home from './components/pages/Home/index'
import { AppContextProvider } from 'components/Data/AppContext'
import {
  UserContextProvider,
  useUserContext
} from 'components/Data/UserContext'

import RotaProtegida from './components/Routes/RotaProtegida'
import { WorkspaceContextProvider } from 'components/Data/WorkspaceContext'
import { IndicatorContextProvider } from 'components/Data/IndicatorsContext'
import NaoEncontrada from 'components/pages/404/NaoEncontrada'
import { AuditoriaContextProvider } from 'components/Data/AuditoriaContext'
import EsqueciSenha from 'components/pages/Home/EsqueciSenha'
import RedefinirSenha from 'components/pages/Home/RedefinirSenha'
import {
  EmbeddedContext,
  EmbeddedContextProvider
} from 'components/Data/EmbeddedContext'

export function App() {
  const { login } = useUserContext()

  return (
    <BrowserRouter>
      <UserContextProvider>
        <WorkspaceContextProvider>
          <IndicatorContextProvider>
            <AppContextProvider>
              <AuditoriaContextProvider>
                <EmbeddedContextProvider>
                  <Routes>
                    <Route path="/">
                      <Login isLogged={login} />
                    </Route>
                    <Route path="/esqueciASenha" element={<EsqueciSenha />} />
                    <Route
                      path="/recoverPassword"
                      element={<RedefinirSenha />}
                    />
                    <Route path="*" element={<NaoEncontrada />} />
                    <RotaProtegida path="home/*" element={<Home />} />
                  </Routes>
                </EmbeddedContextProvider>
              </AuditoriaContextProvider>
            </AppContextProvider>
          </IndicatorContextProvider>
        </WorkspaceContextProvider>
      </UserContextProvider>
    </BrowserRouter>
  )
}

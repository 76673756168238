import React from 'react'
import ContainerBTNS from './ContainerBTNS'
import ContainerLogin from './ContainerLogin'
import { Container, Banner, Logo, ContainerDireita } from './styles'
import { useNavigate } from 'react-router'
import { Navigate } from 'react-router'
import { useUserContext } from 'components/Data/UserContext'

const Index: any = ({ isLogged }) => {
  const [loginMethod, setLoginMethod] = React.useState(false)
  const { login } = useUserContext()

  if (login) {
    return <Navigate to={'/home/0'} />
  }

  return (
    <Container>
      <Banner>
        <Logo />
      </Banner>

      <ContainerDireita>
        <ContainerLogin
          setLoginMethod={setLoginMethod}
          loginMethod={loginMethod}
          titulo="Bem-vindo"
        >
          <ContainerBTNS
            loginMethod={loginMethod}
            setLoginMethod={setLoginMethod}
          />
        </ContainerLogin>
      </ContainerDireita>
    </Container>
  )
}

export default Index

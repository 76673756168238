import styled, { css } from 'styled-components'
import BannerMenina from '../../../assets/imgs/powerbi/menina.svg'
import LogoSVG from '../../../assets/imgs/powerbi/logo.svg'
import LogoHome from '../../../assets/imgs/powerbi/logo-maida.svg'

export const Titulo = styled.h1`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.88);
`
export const Subtitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`

export const ContainerDireita = styled.div`
  position: relative;
  display: flex;
  flex: 30%;
  height: 100vh;
  justify-content: center;

  padding-top: 128px;
  padding-right: 40px;
  padding-bottom: 250px;
  padding-left: 39px;
`
export const LoginContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 376px;
  /* height: 312px; */
  max-height: 500px;
  /* justify-content: space-between; */
  align-items: center;
`
export const Container = styled.div`
  display: flex;

  @media (min-width: 100px) and (max-width: 767px) {
    margin-top: 40px;
    flex-direction: column;
  }
`

export const Banner = styled.div`
  display: flex;
  flex: 70%;

  background: linear-gradient(180deg, rgba(43, 69, 212, 0) 0%, #2b45d4 100%),
    url(${BannerMenina});
  background-position: center left;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 100px) and (max-width: 767px) {
    background: white;
    flex: 2;
  }

  justify-content: center;
  align-items: center;
`

export const Logo = styled.div`
  display: flex;
  width: 525px;
  height: 86px;

  @media (min-width: 100px) and (max-width: 767px) {
    width: 317.625px;
    height: 52.03px;
    background: url(${LogoHome});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
  }

  background: url(${LogoSVG});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  height: 96px;
  width: 376px;
  margin-bottom: 24px;

  & > h1 {
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 20px;
  }
`

export const ContainerBtns = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-self: flex-start; */
  width: 100%;
  /* outline: 2px solid dodgerblue; */
`
export const Divisoria = styled.div`
  ${({ theme }) => css`
    position: relative;
    bottom: 15px;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.08);
    }
    &:hover {
      color: ${theme.colors.powerbi.ascent};
    }
  `}
`
export const TextoDivisoria = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    padding-top: 15px;
    margin-left: 22px;
    font-size: 1rem;
    line-height: 25px;
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.gray};
  `}
`

export const Button = styled.button`
  ${({ theme }) => css`
    font-family: 'Open Sans';
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    font-weight: ${theme.font.semiBold};
    padding: 12px 24px !important;
    box-sizing: border-box;
    border-radius: 1000px;
    cursor: pointer;
    transition: all 0.3s;

    &:not(&:first-of-type) {
      margin-top: 16px;
    }

    &.google {
      background-color: transparent;
      border: 1px solid #00000029;
      color: ${theme.colors.gray};
    }
    &.microsoft {
      background: #2f2f2f;
      border: 1px solid #2f2f2f;
      color: #fff;
    }
    &.email {
      background: transparent;
      border: 2px solid ${theme.colors.powerbi.primary};
      color: ${theme.colors.powerbi.primary};
    }
    & > .buttonIcon {
      display: block;
      margin-right: 8px;
    }
    &:hover {
      /* background-color: #fafafa; */
    }
    &.microsoft:hover {
      /* background-color: #1f1f1f; */
    }

    &:disabled {
      cursor: not-allowed;
      user-select: none;
    }
  `}
`

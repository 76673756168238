import * as S from './styles'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Logo from '../../../assets/imgs/powerbi/logo-maida.svg'
import LogoClosed from '../../../assets/imgs/powerbi/logo-maida-closed.svg'
import { ReactComponent as IconConfig } from '../../../assets/imgs/powerbi/icon-config.svg'
import { ReactComponent as IconDown } from '../../../assets/imgs/mai-ic-chevron-single-down.svg'
import { ReactComponent as IconUp } from '../../../assets/imgs/mai-ic-chevron-single-up.svg'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import { List, Box, Drawer as MuiDrawer } from '@mui/material'
import { useUserContext } from 'components/Data/UserContext'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { API_URL } from '../../Data/API/api'
import { useIndicatorContext } from 'components/Data/IndicatorsContext'
import ListAllItems from './Configurations/ListAllItems'
import { useEmbeddedContext } from 'components/Data/EmbeddedContext'

const drawerWidth = 327

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`
  }
})

const MUIDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const Drawer = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [dropdownActive, setdropdownActive] = useState<boolean>(false)
  const params = useParams()
  const URLparams = String(Object.values(params))
  const { userLogout, userData } = useUserContext()
  const { activeWorkspaces, listActivesWorkspaces } = useWorkspaceContext()
  const { indicators, listIndicatorsByWorkspace } = useIndicatorContext()
  const { services, listAll } = useEmbeddedContext()
  const token = window.localStorage.getItem('token')
  const userAllowedWorkspaces =
    activeWorkspaces &&
    activeWorkspaces.filter(
      (workspace, id) =>
        workspace.name === (userData ? userData?.workspaces[id] : '')
    )

  useEffect(() => {
    listActivesWorkspaces(token)
  }, [token])

  useEffect(() => {
    listActivesWorkspaces(token)
  }, [])

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const handleDropdownActive = () => {
    setdropdownActive(!dropdownActive)
  }

  const setDropdownToFalse = () => {
    setdropdownActive(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <MUIDrawer
        variant="permanent"
        open={open}
        classes={{
          paper: 'drawerPaper'
        }}
        className={`${!open && 'drawerClosed'}`}
      >
        <S.DrawerContent>
          <Link to="/home/0">
            <S.Logo src={open ? Logo : LogoClosed} alt="Maida Analytics" />
          </Link>

          {/* relatórios */}
          <List>
            {userData &&
              userData.roles[0] === 'USER' &&
              userAllowedWorkspaces.map((workspace, id) => {
                return (
                  <S.Button
                    key={id}
                    to={`/home/${workspace.id}`}
                    className={`${!open && 'buttonClosed'}`}
                    onClick={setDropdownToFalse}
                  >
                    {workspace.iconUrl && (
                      <S.Icon className={`${!open && 'iconClosed'}`}>
                        <img
                          src={API_URL + workspace.iconUrl}
                          alt={workspace.id}
                        />
                      </S.Icon>
                    )}
                    <div>{open && workspace.name}</div>
                  </S.Button>
                )
              })}
            {userData &&
              userData.roles[0] === 'ADMIN' &&
              activeWorkspaces.map((workspace, id) => {
                return (
                  <S.Button
                    key={id}
                    to={`/home/${workspace.id}`}
                    className={`${!open && 'buttonClosed'}`}
                    onClick={setDropdownToFalse}
                  >
                    {workspace.iconUrl && (
                      <S.Icon className={`${!open && 'iconClosed'}`}>
                        <img
                          src={API_URL + workspace.iconUrl}
                          alt={workspace.id}
                        />
                      </S.Icon>
                    )}
                    <div>{open && workspace.name}</div>
                  </S.Button>
                )
              })}

            {/* botão de configurações e dropdown */}
            {userData && userData?.roles.includes('ADMIN') && (
              <>
                <S.Button
                  to={'configuracoes/usuarios'}
                  className={`${!open && 'buttonClosed'} ${
                    URLparams.indexOf('configuracoes') > -1 && 'active'
                  }`}
                  onClick={handleDropdownActive}
                >
                  <S.Icon className={`${!open && 'iconClosed'}`}>
                    <IconConfig />
                  </S.Icon>
                  <S.TextButton>{open && 'Configurações'}</S.TextButton>
                  {open && (
                    <S.ArrowIconConfig>
                      {dropdownActive ? <IconUp /> : <IconDown />}
                    </S.ArrowIconConfig>
                  )}
                </S.Button>

                <S.Dropdown
                  className={`${dropdownActive && open && 'dropdownActive'}`}
                >
                  <S.LinkDropdown to="configuracoes/usuarios">
                    Usuários
                  </S.LinkDropdown>
                  <S.LinkDropdown to="configuracoes/relatorios">
                    Relatórios
                  </S.LinkDropdown>
                  <S.LinkDropdown to="configuracoes/indicadores">
                    Indicadores
                  </S.LinkDropdown>
                  <S.LinkDropdown to="configuracoes/auditoria">
                    Auditoria
                  </S.LinkDropdown>
                  <S.LinkDropdown to="configuracoes/embedded">
                    Embedded
                  </S.LinkDropdown>
                </S.Dropdown>
              </>
            )}
          </List>
          {/*  */}
        </S.DrawerContent>
        {/* <S.Button
          to={'/'}
          className={`${!open && 'buttonClosed'}`}
          onClick={userLogout}
        >
          <div>Fazer logout</div>
        </S.Button> */}

        {/* divisória e reduzir menu */}
        <S.Divisoria onClick={handleDrawerOpen}>
          <S.IconButton aria-label="Abrir menu" edge="start">
            {open ? (
              <S.TextoDivisoria>
                <S.ArrowsLeft />
                reduzir menu{' '}
              </S.TextoDivisoria>
            ) : (
              <S.ArrowsRight />
            )}
          </S.IconButton>
        </S.Divisoria>
      </MUIDrawer>
    </Box>
  )
}

export default Drawer

import HomeIndicators from './HomeIndicators'
import * as S from './styles'
import { Routes, Route } from 'react-router-dom'
import Indicators from './Indicators'
import Header from './Header'
import Perfil from './Perfil'
import ListAllItems from './Configurations/ListAllItems'
import CreateReport from './Configurations/Reports/CreateReport'
import EditReport from './Configurations/Reports/EditReport'
import CreateIndicator from './Configurations/Indicators/CreateIndicator'
import EditIndicator from './Configurations/Indicators/EditIndicator'
import CreateCSVUser from './Configurations/Users/CreateCSVUser'

//========== USUARIOS
import CreateUser from './Configurations/Users/CreateUser'
// import EditUser from './Configurations/Users/EditUser'

import { useUserContext } from 'components/Data/UserContext'
import TrocarSenha from './TrocarSenha'
import Auditoria from './Auditoria'
import Embedded from './Embedded'
import CreateEmbedded from './Configurations/Embedded/CreateEmbedded'
import EditEmbedded from './Configurations/Embedded/EditEmbedded'
import AcessosPorRelatorio from './AcessosPorRelatorio'

const Content = () => {
  const { userData } = useUserContext()
  return (
    <S.ContainerContent>
      <Header />
      {userData && userData?.roles && userData?.roles.includes('ADMIN') ? (
        <Routes>
          <Route path="perfil" element={<Perfil />} />
          <Route path="trocarSenha" element={<TrocarSenha />} />
          <Route path=":reportID" element={<HomeIndicators />} />
          <Route path=":reportID/resultadoIndicador" element={<Indicators />} />
          {/* Rotas para Usurios */}
          <Route path="configuracoes/:id" element={<ListAllItems />} />
          <Route
            path="configuracoes/:id/criarUsuario"
            element={<CreateUser />}
          />
          <Route
            path="configuracoes/:id/acessosByReport"
            element={<AcessosPorRelatorio />}
          />
          <Route
            path="configuracoes/:id/criarUsuarioCSV"
            element={<CreateCSVUser />}
          />
          <Route
            path="configuracoes/:id/criarRelatorio"
            element={<CreateReport />}
          />
          <Route
            path="configuracoes/:id/editarRelatorio"
            element={<EditReport />}
          />
          <Route
            path="configuracoes/:id/criarIndicador"
            element={<CreateIndicator />}
          />
          <Route
            path="configuracoes/:id/editarIndicador"
            element={<EditIndicator />}
          />
          <Route path="configuracoes/auditoria" element={<Auditoria />} />
          <Route
            path="configuracoes/:id/criarEmbedded"
            element={<CreateEmbedded />}
          />
          <Route
            path="configuracoes/:id/managerEmbedded"
            element={<EditEmbedded />}
          />
        </Routes>
      ) : (
        // Bloco de rotas para usuario
        //ele vera so os workspaces que lhe foram atribuidos
        <Routes>
          <Route path="perfil" element={<Perfil />} />
          <Route path="trocarSenha" element={<TrocarSenha />} />
          <Route path=":reportID" element={<HomeIndicators />} />
          <Route path=":reportID/resultadoIndicador" element={<Indicators />} />
        </Routes>
      )}
    </S.ContainerContent>
  )
}

export default Content

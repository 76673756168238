import React, { ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { CREATE_LOGS, GET_LOGS } from './API/Auditoria'

interface ContextValue {
  loading: boolean
  errorMessage: string
  open: boolean
  setOpen: any
  severity: string
  setSeverity: any
  feedbackMessage: string
  setFeedbackMessage: any
  logs: Array<any>
  createLog: (token: string | null, formData: any) => void
  listAll: (
    token: string | null,
    increment: boolean,
    dateStart: any,
    dateEnd: any,
    userAccess: number,
    indicator: number
  ) => void
}

interface Props {
  children: ReactNode
}

export const AuditoriaContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
)

export const AuditoriaContextProvider = ({ children }: Props) => {
  const [logs, setLogs] = React.useState([])
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()

  const [totalPagesUsers, setTotalPageUsers] = React.useState<number>(1)
  const [pageUsers, setPageUsers] = React.useState<number>(0)

  //snackbar
  const [open, setOpen] = React.useState<boolean>(false)
  const [severity, setSeverity] = React.useState<string>('')
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('')

  async function createLog(token: string | null, formData: any) {
    try {
      setLoading(true)
      const { url, options } = CREATE_LOGS(token, formData)
      console.log(formData)
      const response = await fetch(url, options)
      const json = await response.json()
      if (!response.ok) {
        throw new Error(`${json.status} - ${json.error}`)
      }
    } catch (err: any) {
      console.log(err)
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function listAll(
    token: string | null,
    increment: boolean,
    dataStart: any,
    dataEnd: any,
    userAccess: number,
    indicator: number
  ) {
    try {
      setLoading(true)
      const t = token ? token : window.localStorage.getItem('token')
      if (increment) {
        setPageUsers((pageUsers ? pageUsers : 0) + 1)
      } else if (!increment) {
        setPageUsers(0)
      }

      //Não deve atualizar caso o numero de páginas seja maior que o numero de total retornado pelo server
      if (totalPagesUsers < pageUsers) {
        setPageUsers(totalPagesUsers)
      }

      const { url, options } = GET_LOGS(
        t,
        pageUsers,
        userAccess,
        indicator,
        dataStart,
        dataEnd
      )
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const json = await response.json()
      const { body } = json
      setLogs(body)
      if (body && Array.isArray(body) && body.length > 0) {
        setSeverity('success')
        setFeedbackMessage('')
        setOpen(false)
      } else {
        setSeverity('info')
        setFeedbackMessage('Não há resultados para a pesquisa!')
        setOpen(true)
      }
    } catch (error) {
      setErrorMessage(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  const value = {
    listAll,
    createLog,
    logs,
    loading,
    open,
    setOpen,
    severity,
    setSeverity,
    feedbackMessage,
    setFeedbackMessage,
    errorMessage
  }

  return (
    <AuditoriaContext.Provider value={value}>
      {children}
    </AuditoriaContext.Provider>
  )
}

export function useAuditoriaContext() {
  const context = useContext(AuditoriaContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useAuditoriaContext must be used within an AuditoriaContext'
    )
  }

  return context
}

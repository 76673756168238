import { Dispatch, useEffect } from 'react'
import { ContainerBtns, Divisoria, TextoDivisoria, Button } from './styles'
import { useNavigate } from 'react-router-dom'
import IconGoogle from '../../../assets/imgs/powerbi/icon-google.svg'
import IconMicrosoft from '../../../assets/imgs/powerbi/icon-microsoft.svg'
import { Providers, ProviderState } from '@microsoft/mgt-element'
import { useUserContext } from 'components/Data/UserContext'
import { useGoogleLogin } from 'react-google-login'
import { configGoogle, configMicrosoft } from 'appsettings'
import { PublicClientApplication } from '@azure/msal-browser'

interface LoginMethodProp {
  loginMethod: boolean
  setLoginMethod: Dispatch<boolean>
}

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: configMicrosoft.appId,
    authority: configMicrosoft.authority,
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  }
})

const ContainerBTNS = ({ loginMethod, setLoginMethod }: LoginMethodProp) => {
  const { userLoginMicrosoft, errorMicrosoft, userLoginGoogle, errorGoogle } =
    useUserContext()

  useEffect(() => {
    // const updateState = async () => {
    //   const provider = Providers.globalProvider
    //   // setLoginMicrosoftMethod(provider && provider.state === ProviderState.SignedIn)
    //   if (provider && provider.state === ProviderState.SignedIn) {
    //     //Verifica se já está logado para entrar automaticamente (Desabilitado porque pode ocasionar uma falha de segurança)
    //     let emailSigned = ''
    //     await provider.graph.client
    //       .api('me')
    //       .get()
    //       .then((gotMe) => (emailSigned = gotMe.userPrincipalName))
    //     userLoginMicrosoft(emailSigned, '')
    //   }
    // }

    // Providers.onProviderUpdated(updateState)
    // updateState()

    // return () => {
    //   Providers.removeProviderUpdatedListener(updateState)
    // }

    const accounts = msalInstance.getAllAccounts()
    if (accounts && accounts.length > 0) {
      const account = accounts[0]
      if (account.username) userLoginMicrosoft(account.username, '')
    }
  }, [msalInstance.getAllAccounts()])

  const onSuccess = (res) => {
    console.log(res)
    const accountLogin = res.profileObj.email
    // console.log(`Login realizado com sucesso! Current user: ${accountLogin}`)
    userLoginGoogle(accountLogin)
  }

  const onFailure = (res) => {
    console.log(`Erro: ${JSON.stringify(res)}`)
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: configGoogle.clientId,
    cookiePolicy: 'single_host_origin',
    accessType: 'offline',
    responseType: 'account'
  })

  return (
    <ContainerBtns style={{ marginTop: loginMethod ? '35px' : '40px' }}>
      {/* {loginMethod && (
        <Divisoria>
          <TextoDivisoria></TextoDivisoria>
        </Divisoria>
      )} */}

      {/* CTA GOOGLE */}
      {/* <Button disabled={false} onClick={signIn} className="google">
        <img
          src={IconGoogle}
          alt="Fazer login com o Google"
          className="buttonIcon"
        />
        Fazer login com o Google
      </Button>
      {errorGoogle && (
        <p style={{ marginTop: '10px', color: 'red', fontSize: '14px' }}>
          {errorGoogle}
        </p>
      )} */}

      {/* CTA MICROSOFT */}
      {/* <Button
        onClick={async () => {
          msalInstance.handleRedirectPromise().then((auth) => {
            const account = msalInstance.getActiveAccount()
            if (!account) {
              msalInstance.loginRedirect()
            }
          })
        }}
        className="microsoft"
        disabled={false}
      >
        <img
          src={IconMicrosoft}
          alt="Fazer login com a Microsoft"
          className="buttonIcon"
        />
        Fazer login com a Microsoft
      </Button>
      {errorMicrosoft && (
        <p style={{ marginTop: '10px', color: 'red', fontSize: '14px' }}>
          {errorMicrosoft}
        </p>
      )} */}

      {/* Ele só mostra o o Entrar com usuario se tiver na tela Inicial, pq significa que ele escolheu entrar
      com E-MAIL */}

      {/* {!loginMethod && (
        <Button className="email" onClick={() => setLoginMethod(!loginMethod)}>
          Entrar com Usuário
        </Button>
      )} */}
    </ContainerBtns>
  )
}

export default ContainerBTNS

import styled from 'styled-components'
import React from 'react'
import './../../../App.css'
import { useAppContext } from '../../Data/AppContext'
import { useUserContext } from '../../Data/UserContext'
import { useAuditoriaContext } from '../../Data/AuditoriaContext'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { Container } from '@mui/material'
const Indicators = () => {
  const {
    currentIndicator,
    getTokenAccessPowerBI,
    tokenAccessPowerBI,
    URLEmbedded
  } = useAppContext()

  const { createLog } = useAuditoriaContext()
  const { userData } = useUserContext()

  React.useEffect(() => {
    const token = localStorage.getItem('token')
    getTokenAccessPowerBI(Number(currentIndicator.id))

    const auditoria = {
      placeAccess: String(
        `Workspace: ${currentIndicator?.workspace} Indicador: ${currentIndicator.name}`
      ),
      user: userData?.email,
      indicador: currentIndicator?.id
    }
    createLog(token, auditoria)
  }, [])

  return (
    <div>
      <Container maxWidth="xl">
        <span className="pink" style={{ width: '100%' }}>
          {`${
            currentIndicator && currentIndicator.name
              ? currentIndicator.name
              : ''
          }`}
        </span>
      </Container>
      <ContainerDados>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%'
          }}
        >
          {tokenAccessPowerBI && (
            <PowerBIEmbed
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                id: currentIndicator.reportCode,
                embedUrl: URLEmbedded,
                accessToken: tokenAccessPowerBI,
                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    },
                    pageNavigation: {
                      visible: false
                    }
                  }
                }
              }}
              cssClassName={'Embed-container'}
            />
          )}
        </div>
        {/* <iframe
          width="1140"
          height="541.25"
          frameBorder={0}
          allowFullScreen={true}
          src={URLEmbedded}
        ></iframe> */}
      </ContainerDados>
    </div>
  )
}

export default Indicators

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

const report = styled.div`
  width: 100%;
  height: 100%;
`

const ContainerDados = styled.div`
  position: absolute;
  width: 80%;
  height: 100vh !important;
`
const SubContainerEsqDados = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`
const SubContainerDirDadosGraficos = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

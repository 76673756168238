import { API_URL } from './api'

export function CREATE_INDICATOR_POST(token, formData) {
  return {
    url: API_URL + '/indicator',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(formData)
    }
  }
}

export function EDIT_INDICATOR_PUT(token, id, formData) {
  return {
    url: API_URL + `/indicator/${id}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(formData)
    }
  }
}

export function DELETE_INDICATOR_DEL(token, id) {
  return {
    url: API_URL + `/indicator/${id}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function LIST_ALL_INDICATOR_GET(token) {
  return {
    url: API_URL + '/indicator',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function LIST_ALL_INDICATOR_GET_WITH_FILTER(token, page, filter) {
  return {
    url: `${API_URL}/indicator?${
      filter ? `filter=${filter}&` : ''
    }page=${Number(page)}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

import { API_URL } from './api'

export function CREATE_WORKSPACE_POST(token, formData) {
  return {
    url: API_URL + '/workspace',
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: formData
    }
  }
}

export function EDIT_WORKSPACE_PUT(token, id, formData) {
  return {
    url: API_URL + `/workspace/${id}`,
    options: {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: formData
    }
  }
}

export function DELETE_WORKSPACE_DEL(token, id) {
  return {
    url: API_URL + `/workspace/${id}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function LIST_ACTIVES_WORKSPACES_GET(token) {
  return {
    url: API_URL + '/workspace',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function LIST_ALL_WORKSPACES_GET(token) {
  return {
    url: API_URL + '/workspace/all',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import IconAtt from './IconAttachment'
import { FormControlLabel, FormGroup, TextField } from '@mui/material'
import Switch from '../../Switch'
import MaidaBtn from 'components/MaidaBtn'
import { useState } from 'react'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { useUserContext } from 'components/Data/UserContext'
import NewSnackbar from 'components/NewSnackbar'

const Input = styledMUI('input')({
  display: 'none'
})

const FileText = styled.span`
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 24px;
`
const InfoText = styled.span`
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 42px;
  color: #ff0073;
`
const ButtonSave = styled(MaidaBtn)`
  float: right;
  margin-top: 30px;
`
const IconCard = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  background: #2b45d4;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 20px;
  padding-right: 100px;
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 16px;
  cursor: pointer;
`
const IconImage = styled.span`
  display: flex;
  align-items: center;
  margin-right: 19px;

  & > svg > path {
    fill: #fff;
  }
`

const CreateReport = () => {
  const [name, setName] = useState('')
  const [error, setError] = useState([false, ''])
  const [file, setFile] = useState<string | Blob | null>(null)
  const [fileError, setFileError] = useState([false, ''])
  const [isReportActive, setIsReportActive] = useState(true)
  const {
    createWorkspace,
    loading,
    open,
    setOpen,
    severity,
    setSeverity,
    feedbackMessage,
    setFeedbackMessage
  } = useWorkspaceContext()
  const { token } = useUserContext()

  function handleSubmit(e) {
    e.preventDefault()
    if (validateName(name) && validateFile(file)) {
      const formData = new FormData()
      formData.append('name', name)
      formData.append('file', file as any)
      formData.append('active', isReportActive as any)
      // console.log(JSON.stringify(formData))
      createWorkspace(token, formData)
    } else {
      setSeverity('error')
      setFeedbackMessage('Sintaxe ou arquivo inválidos, tente novamente.')
      setOpen(true)
    }
  }

  function handleFileChange({ target }) {
    if (!target.files) setFileError([true, 'Arquivo não selecionado!'])
    if (fileError[0]) validateFile(target.files[0])
    if (validateFile(target.files[0])) setFile(target.files[0])
  }

  function validateFile(value) {
    if (!(value['type'] === 'image/png' || value['type'] === 'image/svg+xml')) {
      setFile(null)
      setFileError([true, 'O formato do arquivo deve ser em .png ou .svg!'])
      return false
    } else {
      setFileError([false, ''])
      return true
    }
  }

  function handleSetName({ target }) {
    setName(target.value)
    if (error[0]) validateName(target.value)
  }

  function handleNameChange({ target }) {
    validateName(target.value)
  }

  function validateName(value) {
    if (value.length === 0) {
      setError([true, 'Preencha um nome.'])
      return false
    } else if (value.length >= 40) {
      setError([true, 'O nome precisa ter menos de 40 caracteres.'])
      return false
    } else {
      setError([false, ''])
      return true
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {/* nome do relatório */}
        <TextField
          id="name"
          value={name}
          onChange={handleSetName}
          onBlur={handleNameChange}
          label="Nome do relatório"
          variant="outlined"
          fullWidth
          helperText={error[0] && error[1]}
          InputProps={{
            style: {
              display: 'flex',
              alignItems: 'center',
              padding: '1rem 20px',
              boxSizing: 'border-box',
              fontFamily: 'Open Sans, sans-serif',
              fontWeight: 'normal',
              fontSize: '1rem',
              lineHeight: '24px',
              letterSpacing: '0'
            }
          }}
          inputProps={{
            style: {
              padding: '0'
            }
          }}
          sx={{
            '& .MuiInputLabel-root': {
              fontFamily: 'Open Sans, sans-serif',
              fontWeight: 'normal',
              fontSize: '1rem',
              lineHeight: '24px',
              color: 'rgba(0, 0, 0, 0.56)',
              letterSpacing: '0'
            },
            '& [class*="MuiOutlinedInput"]': {
              borderColor: 'rgba(0, 0, 0, 0.16)'
            }
          }}
        />
        {/* ícone do relatório */}

        <FileText>Adicione um ícone para o relatório</FileText>
        <label htmlFor="icon-report">
          <Input
            accept="image/png, image/svg+xml"
            id="icon-report"
            type="file"
            onChange={handleFileChange}
          />
          <Button
            variant="outlined"
            component="span"
            startIcon={<IconAtt />}
            fullWidth
            sx={{
              padding: '1rem 20px',
              boxSizing: 'border-box',
              border: '1px solid rgba(0, 0, 0, 0.16)',
              color: 'rgba(0, 0, 0, 0.56)',
              textTransform: 'none',
              fontFamily: 'Open Sans, sans-serif',
              fontWeight: 'normal',
              fontSize: '1rem',
              lineHeight: '24px',
              justifyContent: 'start',
              margin: '16px 0',
              letterSpacing: '0',
              '& .MuiButton-startIcon': {
                marginRight: '19px'
              },
              '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#000'
              }
            }}
          >
            Anexar ícone
          </Button>
          {file && (
            <IconCard>
              <IconImage>
                <IconAtt />
              </IconImage>
              <div>{file['name']}</div>
            </IconCard>
          )}
        </label>
        {fileError[0] && (
          <InfoText
            style={{ color: 'red', marginBottom: '16px', fontSize: '14px' }}
          >
            {fileError[1]}
          </InfoText>
        )}
        <InfoText>.SVG ou .PNG</InfoText>

        {/* relatório ativo */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                rounded={true}
                isToggled={isReportActive}
                onToggle={() => {
                  setIsReportActive(!isReportActive)
                }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: 'bold',
                  letterSpacing: '0',
                  display: 'inline-block',
                  marginLeft: '18px',
                  fontSize: '1rem',
                  lineHeight: '24px',
                  color: 'rgba(0, 0, 0, 0.56)'
                }}
              >
                Relatório ativo
              </span>
            }
            sx={{
              marginLeft: '0'
            }}
          />
        </FormGroup>
        {loading ? (
          <ButtonSave text="Carregando..." disabled={true} />
        ) : (
          <ButtonSave text="Salvar" disabled={name && file ? false : true} />
        )}
      </form>
      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </>
  )
}

export default CreateReport

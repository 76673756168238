import FilterHeader from './FilterHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from 'components/Data/AppContext'
import ButtonAdd, { BasicButton, ButtonAccess } from './ButtonAdd'
import DataList from './DataList'
import SearchBar from './SearchBar'
import styled from 'styled-components'
import IconDownload from './IconDownload'
import { useEmbeddedContext } from 'components/Data/EmbeddedContext'

const WrapperSearch = styled.div`
  margin-top: 50px;

  @media (max-width: 930px) {
    margin-top: 30px;
  }
`
const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 930px) {
    flex-direction: column;
  }
`
const CsvButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 240px;
  background: #2b45d4;
  border-radius: 8px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  padding: 16px 19px;
  box-sizing: border-box;
  max-height: 56px;
  & a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1020px) {
    flex: 1 1 260px;
  }

  @media (max-width: 930px) {
    margin: 16px 0 0 0;
    flex: 1;
  }
`

const ListAllItems = () => {
  const navigate = useNavigate()
  const { reports, servicesEmbedded } = useAppContext()
  const { id } = useParams()
  return (
    <div>
      {id === 'usuarios' && (
        <>
          <WrapperButtons>
            <ButtonAdd
              text={'Adicionar novo usuário'}
              onClick={() => {
                navigate('criarUsuario')
              }}
            />
            {/* <ButtonAccess
              text={'Acessos por Relatório'}
              onClick={() => {
                navigate('acessosByReport')
              }}
            /> */}
            <CsvButton onClick={() => navigate('criarUsuarioCSV')}>
              <IconDownload />
              <div style={{ marginLeft: '8px' }}>Importar .csv</div>
            </CsvButton>
          </WrapperButtons>
          <WrapperSearch>
            <SearchBar />
          </WrapperSearch>
          <FilterHeader />
        </>
      )}
      {id === 'relatorios' && (
        <>
          <WrapperButtons>
            <ButtonAdd
              text={'Adicionar novo relatório'}
              onClick={() => navigate('criarRelatorio')}
            ></ButtonAdd>
            <ButtonAccess
              text={'Acessos por Relatório'}
              onClick={() => {
                navigate('acessosByReport')
              }}
            />
          </WrapperButtons>
          <FilterHeader />
          <DataList itens={reports} />
        </>
      )}
      {id === 'indicadores' && (
        <>
          <ButtonAdd
            text={'Adicionar novo indicador'}
            onClick={() => navigate('criarIndicador')}
          ></ButtonAdd>
          <WrapperSearch>
            <SearchBar />
          </WrapperSearch>
          <FilterHeader />
        </>
      )}
      {id === 'auditoria' && (
        <>
          <WrapperSearch>
            <SearchBar />
          </WrapperSearch>
        </>
      )}
      {id === 'embedded' && (
        <>
          <ButtonAdd
            text={'Adicionar novo Serviço'}
            onClick={() => navigate('criarEmbedded')}
          ></ButtonAdd>
          <FilterHeader />
        </>
      )}
    </div>
  )
}

export default ListAllItems

import { useAppContext } from 'components/Data/AppContext'
import { useIndicatorContext } from 'components/Data/IndicatorsContext'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import * as S from './styles'

const HomeIndicators = () => {
  const { reports, setCurrentIndicator } = useAppContext()
  const { reportID = 0 } = useParams()
  const [currentReport, setCurrentReport] = useState(reports[0])

  React.useEffect(() => {
    setCurrentReport(reports[0])
    reports.forEach((r) => {
      if (r.id == reportID) {
        setCurrentReport(r)
        console.log(r)
      }
    })
  }, [reportID, reports])

  return (
    <section>
      <span className="pink">
        Indicadores{' '}
        {currentReport && currentReport.name ? currentReport.name : ''}
      </span>
      <S.WrapperCards>
        {currentReport &&
          currentReport.indicators &&
          currentReport.indicators.map((indicator) => {
            if (indicator.active) {
              return (
                <S.CardIndicator
                  key={indicator.id}
                  onClick={() => setCurrentIndicator(indicator)}
                  to="resultadoIndicador"
                >
                  <S.ConteudoCardIndicator>
                    <S.Titulo>{indicator.name}</S.Titulo>
                    <p>{indicator.description}</p>
                  </S.ConteudoCardIndicator>
                </S.CardIndicator>
              )
            }
          })}
      </S.WrapperCards>
    </section>
  )
}

export default HomeIndicators

// export const API_URL = 'https://localhost:8443'
export const API_URL = 'https://analytics-api.maida.health'

export function TOKEN_POST(body) {
  return {
    url: API_URL + '/auth',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  }
}

export function TOKEN_MICROSOFT_POST(body) {
  return {
    url: API_URL + '/auth/microsoft',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  }
}

export function TOKEN_GOOGLE_POST(body) {
  return {
    url: API_URL + '/auth/google',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  }
}

export function FORGET_PASSWORD(body) {
  return {
    url: API_URL + '/auth/forgetPassword',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    }
  }
}

export function CHANGE_FORGETED_PASSWORD(body) {
  return {
    url: API_URL + '/auth/changeForgetedPassword',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  }
}

export function TOKEN_BI(token, idIndicador) {
  return {
    url: API_URL + `/powerbi/${idIndicador}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
  }
}

import { makeStyles } from '@material-ui/styles'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import IconAtt from './IconAttachment'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField
} from '@mui/material'
import Switch from '../../Switch'
import MaidaBtn from 'components/MaidaBtn'
import { useEffect, useState } from 'react'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { useUserContext } from 'components/Data/UserContext'
import NewSnackbar from 'components/NewSnackbar'
import { useEmbeddedContext } from 'components/Data/EmbeddedContext'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '50%',
    paddingBottom: '24px'
  }
})

const Input = styledMUI('input')({
  display: 'none'
})

const FileText = styled.span`
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 24px;
`
const InfoText = styled.span`
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 42px;
  color: #ff0073;
`
const ButtonSave = styled(MaidaBtn)`
  float: right;
  margin-top: 30px;
`
const IconCard = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  background: #2b45d4;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 20px;
  padding-right: 100px;
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 16px;
  cursor: pointer;
`
const IconImage = styled.span`
  display: flex;
  align-items: center;
  margin-right: 19px;

  & > svg > path {
    fill: #fff;
  }
`

const EditEmbedded = () => {
  const {
    setEmbeddedValue,
    resumeService,
    pauseService,
    service,
    loading,
    open,
    setOpen,
    severity,
    setSeverity,
    feedbackMessage,
    setFeedbackMessage
  } = useEmbeddedContext()
  const classes = useStyles()
  const [name, setName] = useState(String(service?.name))
  const [description, setDescription] = useState(String(service?.description))
  const [idAssinatura, setIdAssinatura] = useState(
    String(service?.idAssinatura)
  )
  const [grupoRecurso, setGrupoRecurso] = useState(
    String(service?.grupoRecurso)
  )
  const [recurso, setRecurso] = useState(String(service?.recurso))
  const [errorName, setErrorName] = useState([false, ''])
  const [errorDescription, setErrorDescription] = useState([false, ''])
  const [errorIdAssinatura, setErrorIdAssinatura] = useState([false, ''])
  const [errorGrupoRecurso, setErrorGrupoRecurso] = useState([false, ''])
  const [errorRecurso, setErrorRecurso] = useState([false, ''])
  const [isReportActive, setIsReportActive] = useState(Boolean(service?.active))

  useEffect(() => {
    return () => {
      povoar()
    }
  }, [service])

  function handleSubmitPausar(e) {
    e.preventDefault()
    const token = window.localStorage.getItem('token')
    pauseService(token, service)
  }

  function handleSubmitContinuar(e) {
    e.preventDefault()
    const token = window.localStorage.getItem('token')
    resumeService(token, service)
  }

  function handleSetName({ target }) {
    setName(target.value)
    if (errorName[0]) validateName(target.value)
  }

  function handleChangeName({ target }) {
    validateName(target.value)
  }

  function validateName(value) {
    if (value.length === 0) {
      setErrorName([true, 'Preencha um nome.'])
      return false
    } else if (value.length >= 40) {
      setErrorName([true, 'O nome precisa ter menos de 40 caracteres.'])
      return false
    } else {
      setErrorName([false, ''])
      return true
    }
  }

  function handleSetDescription({ target }) {
    setDescription(target.value)
    if (errorDescription[0]) validateDescription(target.value)
  }

  function handleChangeDescription({ target }) {
    validateDescription(target.value)
  }

  function validateDescription(value) {
    if (value.length === 0) {
      setErrorDescription([true, 'Preencha um nome.'])
      return false
    } else if (value.length >= 40) {
      setErrorDescription([true, 'O nome precisa ter menos de 40 caracteres.'])
      return false
    } else {
      setErrorDescription([false, ''])
      return true
    }
  }

  function handleSetIdAssinatura({ target }) {
    setIdAssinatura(target.value)
    if (errorIdAssinatura[0]) validateIdAssinatura(target.value)
  }

  function handleChangeIdAssinatura({ target }) {
    validateIdAssinatura(target.value)
  }

  function validateIdAssinatura(value) {
    if (value.length === 0) {
      setErrorIdAssinatura([true, 'Preencha um nome.'])
      return false
    } else if (value.length >= 40) {
      setErrorIdAssinatura([true, 'O nome precisa ter menos de 40 caracteres.'])
      return false
    } else {
      setErrorIdAssinatura([false, ''])
      return true
    }
  }

  function handleSetGrupoRecurso({ target }) {
    setGrupoRecurso(target.value)
    if (errorGrupoRecurso[0]) validateGrupoRecurso(target.value)
  }

  function handleChangeGrupoRecurso({ target }) {
    validateGrupoRecurso(target.value)
  }

  function validateGrupoRecurso(value) {
    if (value.length === 0) {
      setErrorGrupoRecurso([true, 'Preencha um nome.'])
      return false
    } else if (value.length >= 40) {
      setErrorGrupoRecurso([true, 'O nome precisa ter menos de 40 caracteres.'])
      return false
    } else {
      setErrorGrupoRecurso([false, ''])
      return true
    }
  }

  function handleSetRecurso({ target }) {
    setRecurso(target.value)
    if (errorRecurso[0]) validateRecurso(target.value)
  }

  function handleChangeRecurso({ target }) {
    validateRecurso(target.value)
  }

  function validateRecurso(value) {
    if (value.length === 0) {
      setErrorRecurso([true, 'Preencha um nome.'])
      return false
    } else if (value.length >= 40) {
      setErrorRecurso([true, 'O nome precisa ter menos de 40 caracteres.'])
      return false
    } else {
      setErrorRecurso([false, ''])
      return true
    }
  }

  function povoar() {
    setName(service?.name)
    setDescription(service?.description)
    setIdAssinatura(service?.idAssinatura)
    setGrupoRecurso(service?.grupoRecurso)
    setRecurso(service?.recurso)
  }

  return (
    <>
      <form>
        {/* nome do relatório */}
        <FormControl className={classes.input}>
          <TextField
            name="nome"
            required
            id="nome"
            label="Nome Completo"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetName}
            onBlur={handleChangeName}
            helperText={errorName[0] && errorName[1]}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>
        <FormControl className={classes.input}>
          <TextField
            name="nome"
            required
            id="nome"
            label="Descrição"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetDescription}
            onBlur={handleChangeDescription}
            helperText={errorDescription[0] && errorDescription[1]}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>
        <FormControl className={classes.input}>
          <TextField
            name="nome"
            required
            id="nome"
            label="Id Assinatura"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetIdAssinatura}
            onBlur={handleChangeIdAssinatura}
            helperText={errorIdAssinatura[0] && errorIdAssinatura[1]}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>
        <FormControl className={classes.input}>
          <TextField
            name="nome"
            required
            id="nome"
            label="Nome do Grupo de Recurso"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetGrupoRecurso}
            onBlur={handleChangeGrupoRecurso}
            helperText={errorGrupoRecurso[0] && errorGrupoRecurso[1]}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>
        <FormControl className={classes.input}>
          <TextField
            name="nome"
            required
            id="nome"
            label="Nome do Recurso"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetRecurso}
            onBlur={handleChangeRecurso}
            helperText={errorRecurso[0] && errorRecurso[1]}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>

        {/* relatório ativo */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                rounded={true}
                isToggled={isReportActive}
                onToggle={() => {
                  setIsReportActive(!isReportActive)
                }}
              />
            }
            label={
              <span
                style={{
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: 'bold',
                  letterSpacing: '0',
                  display: 'inline-block',
                  marginLeft: '18px',
                  fontSize: '1rem',
                  lineHeight: '24px',
                  color: 'rgba(0, 0, 0, 0.56)'
                }}
              >
                Relatório ativo
              </span>
            }
            sx={{
              marginLeft: '0'
            }}
          />
        </FormGroup>
        {loading ? (
          <ButtonSave text="Carregando..." disabled={true} />
        ) : (
          <ButtonSave
            text="Pausar Serviço"
            disabled={
              name && description && idAssinatura && grupoRecurso && recurso
                ? false
                : true
            }
            onClick={handleSubmitPausar}
            type="submit"
          />
        )}

        {loading ? (
          <ButtonSave text="Carregando..." disabled={true} />
        ) : (
          <ButtonSave
            text="Continuar Serviço"
            disabled={
              name && description && idAssinatura && grupoRecurso && recurso
                ? false
                : true
            }
            onClick={handleSubmitContinuar}
            type="submit"
          />
        )}
      </form>
      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </>
  )
}

export default EditEmbedded

import React, { ReactElement, ReactNode, useContext } from 'react'
import { IndicatorProps } from './Types/IndicatorType'
import { UserProps } from './Types/UserType'
import { WorkspaceProps } from './Types/WorkspaceType'
import { useWorkspaceContext } from './WorkspaceContext'
import * as msal from '@azure/msal-node'
import Axios, { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import { AuthError, AuthResponse, UserAgentApplication } from 'msal'
import { service, factories, models, IEmbedConfiguration } from 'powerbi-client'
import { TOKEN_BI } from './API/api'
import { ReportProblemOutlined } from '@mui/icons-material'
import { useEmbeddedContext } from './EmbeddedContext'
const powerbi = new service.Service(
  factories.hpmFactory,
  factories.wpmpFactory,
  factories.routerFactory
)

interface ContextValue {
  reports: Array<WorkspaceProps>
  servicesEmbedded: Array<any>
  currentIndicator: any
  setCurrentIndicator: (indicator: any) => void
  users: Array<UserProps>
  medias: Array<any>
  dados: Array<any>
  allIndicatorsSum: number
  allUsersSum: number
  allReportsSum: number
  allEmbeddedSum: number
  removerUsuarios: (id: number) => void
  addUsuario: (usuario: UserProps) => void
  getTokenAccessPowerBI: (reportId: number) => void
  tokenAccessPowerBI: string
  expirationTokenAccessPowerBI: string
  URLEmbedded: string
  filterSearch: string
  setFilter: (filter: string) => void
}

interface Props {
  children: ReactNode
}

const AppContext = React.createContext<ContextValue | undefined>(void 0)

export function AppContextProvider(props: Props) {
  const { children } = props
  const { activeWorkspaces } = useWorkspaceContext()
  const { services } = useEmbeddedContext()
  const [currentIndicator, setIndicatorData] = React.useState({})

  const reports = activeWorkspaces
  const servicesEmbedded = services

  const [users, setUsers] = React.useState<Array<UserProps>>([])
  const [tokenAccessPowerBI, setTokenAccessBI] = React.useState('')
  const [URLEmbedded, setURLEmbedded] = React.useState('')
  const [filterSearch, setFilterSearch] = React.useState('')
  const [expirationTokenAccessPowerBI, setExpirationTokenAccessBI] =
    React.useState('')

  const addUsuario = (usuario: UserProps) => {
    users.push(usuario)
  }

  const setFilter = (filter: string) => {
    setFilterSearch(filter)
  }

  const removerUsuarios = (id: number) => {
    const novoArray = users.filter((user) => user.id !== id)
    setUsers(novoArray)
  }

  const setCurrentIndicator = (indicator: any) => {
    setIndicatorData(indicator)
  }

  const getTokenAccessPowerBI = async (reportId: number) => {
    setExpirationTokenAccessBI('')
    setTokenAccessBI('')
    setURLEmbedded('')
    const token = window.localStorage.getItem('token')
    const { url, options } = TOKEN_BI(token, reportId)
    const response = await fetch(url, options)
    if (response.ok) {
      response.json().then((json) => {
        // console.log(json)
        const { embedToken, tokenExpiry, embedReports } = json
        if (embedReports && embedReports.length > 0) {
          setURLEmbedded(embedReports[0].embedUrl)
        }
        setExpirationTokenAccessBI(tokenExpiry)
        setTokenAccessBI(embedToken)

        console.log(
          `URL: ${JSON.stringify(
            embedReports
          )}\nToken: ${embedToken}\nExpiration: ${tokenExpiry}`
        )
      })
    }
  }

  const allUsersSum = users.length
  const allReportsSum = reports.length
  const allEmbeddedSum = servicesEmbedded ? servicesEmbedded.length : 0

  const getIndicators = reports.map((item) =>
    item.indicators ? item.indicators : []
  )
  let allIndicatorsSum = 0

  for (let i = 0; i < getIndicators.length; i++) {
    allIndicatorsSum += getIndicators[i].length
  }

  //=================== Dados demográficos = tela do gráfico
  //=================== simula dados de um indicador especifico
  const medias = [
    { numero: -20.29, texto: 'Média de espera paciente' },
    { numero: 5, texto: 'Média pont. paciente' },
    { numero: 62.84, texto: 'Média de Atendimento' },
    { numero: 4.62, texto: 'Média pont. médico' }
  ]

  const dados = [
    { numero: 1984, texto: 'Total consultas', percentual: null },
    { numero: 762, texto: 'Finalizadas', percentual: 36.59 },
    { numero: 223, texto: 'No show', percentual: 11.24 }
  ]

  const value = {
    reports,
    servicesEmbedded,
    users,
    currentIndicator,
    setCurrentIndicator,
    allUsersSum,
    allReportsSum,
    allIndicatorsSum,
    allEmbeddedSum,
    medias,
    dados,
    removerUsuarios,
    addUsuario,
    getTokenAccessPowerBI,
    URLEmbedded,
    filterSearch,
    setFilter,
    tokenAccessPowerBI,
    expirationTokenAccessPowerBI
  }
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export function useAppContext() {
  const context = useContext(AppContext)

  if (typeof context === 'undefined') {
    throw new Error('useAppContext must be used within an AppContext')
  }

  return context
}

import styled from 'styled-components'
import './CreateUser.css'

import { useUserContext } from '../../../../Data/UserContext'

//Feedback - confirmações ao enviar ou erros
import NewSnackbar from 'components/NewSnackbar'
import FormCreate from 'components/Forms/FormCreate'
import FormEdit from 'components/Forms/FormEdit'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { useEffect } from 'react'

const CreateUser = () => {
  const { open, setOpen, severity, feedbackMessage, currentUser, token } =
    useUserContext()

  return (
    <Container>
      {currentUser ? <FormEdit /> : <FormCreate />}
      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </Container>
  )
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const ContainerGroupSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
`

export const WorkspacesBox = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px 14px;
`

export const Botao = styled.div`
  float: right;
  margin-top: 32px;
`

export const Situacao = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
`
export const Titulo = styled.h2`
  font-weight: 600;
`
export const ErrorSenha = styled.span`
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  margin-top: 5px;
  margin-left: 14px;
`

export default CreateUser

const IconDownload = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 12.5858L14.7928 10.7929C15.1833 10.4024 15.8164 10.4024 16.207 10.7929C16.5975 11.1834 16.5975 11.8165 16.207 12.2071L12.7071 15.7073C12.5196 15.8948 12.2652 16.0002 12 16.0002C11.7348 16.0002 11.4804 15.8948 11.2929 15.7073L7.79287 12.2071C7.40236 11.8166 7.40238 11.1834 7.79291 10.7929C8.18345 10.4024 8.81661 10.4024 9.20713 10.7929L11 12.5859V4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4V12.5858Z"
        fill="white"
      />
      <path
        d="M4 12C4.55228 12 5 12.4477 5 13L5 19H19V13C19 12.4477 19.4477 12 20 12C20.5523 12 21 12.4477 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13C3 12.4477 3.44772 12 4 12Z"
        fill="white"
      />
    </svg>
  )
}

export default IconDownload

import { API_URL } from './api'

export function GET_LOGS(token, page, user, indicator, dataStart, dataEnd) {
  return {
    url: `${API_URL}/auditoria?indicator=${indicator}&user=${user}&dateStart=${dataStart}&dateEnd=${dataEnd}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function CREATE_LOGS(token, body) {
  return {
    url: `${API_URL}/auditoria`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

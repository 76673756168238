import { ReactComponent as IconAdd } from '../../../../assets/imgs/mai-ic-plus.svg'
import { ReactComponent as IconRestrictAccess } from '../../../../assets/imgs/mai-ic-password.mono.svg'
import styled, { css } from 'styled-components'
import { ReactNode } from 'react'

const Button = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background-color: #fff;
  cursor: pointer;
  padding: 18px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  max-height: 56px;
  margin-right: 10px;
  transition: all 0.3s;
  &:hover {
    background-color: #fafafa;
  }
`
const Icon = styled(IconAdd)`
  margin-right: 12px;
  display: block;
  ${({ theme }) => css`
    & > path {
      fill: ${theme.colors.powerbi.ascent};
    }
  `}
`
const IconAccess = styled(IconRestrictAccess)`
  margin-right: 12px;
  display: block;
  ${({ theme }) => css`
    & > path {
      fill: ${theme.colors.powerbi.ascent};
    }
  `}
`
const Text = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 24px;
    color: ${theme.colors.gray};
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
  `}
`
interface Props {
  text: string
  onClick: () => void
  children?: ReactNode
}

const ButtonAdd: React.FC<Props> = ({ text, onClick, children }) => {
  return (
    <Button onClick={onClick}>
      <div>
        <Icon />
      </div>
      <div>
        <Text>{text}</Text>
      </div>
      {children}
    </Button>
  )
}

export const ButtonAccess: React.FC<Props> = ({ text, onClick, children }) => {
  return (
    <Button onClick={onClick}>
      <div>
        <IconAccess />
      </div>
      <div>
        <Text>{text}</Text>
      </div>
      {children}
    </Button>
  )
}

export const BasicButton: React.FC<Props> = ({ text, onClick, children }) => {
  return (
    <Button onClick={onClick}>
      <div>
        <Text>{text}</Text>
      </div>
      {children}
    </Button>
  )
}

export default ButtonAdd

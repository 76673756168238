import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

export default function AlertDialog({
  isDialogOpen,
  setIsDialogOpen,
  setDeleteConfirm,
  message,
  funcaoAposConfirmar
}) {
  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const handleConfirm = () => {
    // setDeleteConfirm(true)
    setIsDialogOpen(false)
    funcaoAposConfirmar()
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Não</Button>
        <Button onClick={handleConfirm} autoFocus>
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

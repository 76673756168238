import React, { Dispatch } from 'react'
import { LoginContainer, Header, Titulo, Subtitle } from './styles'
import styled, { css } from 'styled-components'
import MaidaBtn from '../../MaidaBtn'
import Back from '../Home/Back'
import IconEmail from './IconEmail'
import IconPassword from './IconPassword'
import { useUserContext } from 'components/Data/UserContext'
import { useNavigate } from 'react-router'

interface TituloProp {
  titulo: string
  children?: React.ReactElement
  loginMethod: boolean
  setLoginMethod: Dispatch<boolean>
}

const ContainerLogin = ({
  titulo,
  children,
  loginMethod,
  setLoginMethod
}: TituloProp) => {
  const navigate = useNavigate()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [emailError, setEmailError] = React.useState<string | null>()
  const [passwordError, setPasswordError] = React.useState<string | null>()

  const { error, loading, userLogin } = useUserContext()

  async function handleSubmit(e) {
    e.preventDefault()
    if (validateEmail(email) && validatePassword(password)) {
      userLogin(email, password)
    } else {
      alert('Falha ao logar')
    }
  }

  const validateEmail = (valor) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (valor.length === 0) {
      setEmailError('Preencha um valor')
      return false
    } else if (!regex.test(valor)) {
      setEmailError('Digite um e-mail válido')
      return false
    } else {
      setEmailError(null)
      return true
    }
  }

  const validatePassword = (valor) => {
    if (valor.length === 0) {
      setPasswordError('Preencha uma senha')
      return false
    } else if (valor.length <= 3) {
      setPasswordError('Senha fraca')
      return false
    } else {
      setPasswordError(null)
      return true
    }
  }

  const handleEmailBlur = ({ target }) => {
    validateEmail(target.value)
  }

  const handleEmailChange = ({ target }) => {
    if (emailError) validateEmail(target.value)
    setEmail(target.value)
  }

  const handlePasswordBlur = ({ target }) => {
    validatePassword(target.value)
  }

  const handlePasswordChange = ({ target }) => {
    if (passwordError) validatePassword(target.value)
    setPassword(target.value)
  }

  return (
    <LoginContainer>
      {loginMethod ? (
        <BackButton onClick={() => setLoginMethod(!loginMethod)}>
          <Back link={''} />
        </BackButton>
      ) : (
        '  '
      )}
      <Header>
        <Titulo>{loginMethod ? 'Entrar com e-mail' : titulo}</Titulo>
        <Subtitle>Preencha os campos abaixo para acessar o sistema.</Subtitle>
      </Header>

      {!loginMethod && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '24px'
          }}
        >
          <form
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
            onSubmit={handleSubmit}
          >
            <div>
              <InputBox>
                <IconEmail />
                <Input
                  onBlur={handleEmailBlur}
                  onChange={handleEmailChange}
                  placeholder="usuario.maida@maida.health"
                  className={emailError ? '' : 'marginBottom'}
                />
              </InputBox>
              {emailError && (
                <Error style={{ marginBottom: '16px' }}>{emailError}</Error>
              )}
            </div>
            <div>
              <InputBox>
                <IconPassword />
                <Input
                  onBlur={handlePasswordBlur}
                  onChange={handlePasswordChange}
                  placeholder="Senha"
                  type="password"
                />
              </InputBox>
              {passwordError && <Error>{passwordError}</Error>}
            </div>
            {error && (
              <p style={{ marginTop: '10px', color: 'red', fontSize: '14px' }}>
                {error}
              </p>
            )}
            {/* Esqueceu a senha */}
            <div style={{ marginTop: '20px' }}>
              <a
                style={{
                  fontSize: '12px',
                  color: '#2B45D4',
                  cursor: 'pointer'
                }}
                onClick={() => navigate('/esqueciASenha')}
              >
                Esqueceu a senha?
              </a>
              <div style={{ marginTop: '8px' }}>
                {loading ? (
                  <MaidaBtn
                    text="Carregando..."
                    type="submit"
                    disabled={true}
                  />
                ) : (
                  <MaidaBtn text="Entrar" type="submit" disabled={!email} />
                )}
              </div>
            </div>
          </form>
        </div>
      )}
      {children}
    </LoginContainer>
  )
}

const InputBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`
const Error = styled.p`
  font-size: 14px;
  padding-top: 6px;
  color: red;
`

const Input = styled.input`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 12px 20px 12px 56px;
    border: 2px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.black};
    font-family: 'Open Sans', sans-serif;

    &:focus {
      border: 2px solid ${theme.colors.powerbi.primary};
      outline: none;
    }
    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
    }

    &.marginBottom {
      margin-bottom: 16px;
    }
  `}
`

const BackButton = styled.span`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: -90px;
  left: -10px;
`

export default ContainerLogin

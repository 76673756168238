import React, { ReactNode, useContext } from 'react'
import {
  CREATE_INDICATOR_POST,
  EDIT_INDICATOR_PUT,
  DELETE_INDICATOR_DEL,
  LIST_ALL_INDICATOR_GET,
  LIST_ALL_INDICATOR_GET_WITH_FILTER
} from './API/Indicators'
import { useNavigate } from 'react-router-dom'
import { IndicatorProps } from './Types/IndicatorType'

interface ContextValue {
  indicators: Array<any>
  indicatorData: any
  loading: boolean
  errorMessage: string
  open: boolean
  setOpen: any
  severity: string
  feedbackMessage: string
  createIndicator: (token: string | null, formData: any) => void
  editIndicator: (token: string | null, id: number, formData: any) => void
  deleteIndicator: (token: string | null, id: number) => void
  listIndicatorsByWorkspace: (
    token: string | null,
    workspaceId: number | null
  ) => void
  getIndicatorsWithFilter: (
    token: string | null,
    incrementPage: boolean,
    filter: string | null
  ) => void
  setIndicator: (formData: any) => void
}

interface Props {
  children: ReactNode
}

export const IndicatorContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
)

export const IndicatorContextProvider = ({ children }: Props) => {
  const [indicators, setIndicators] = React.useState([])
  const [indicatorData, setIndicatorData] = React.useState({})
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()
  //snackbar
  const [open, setOpen] = React.useState<boolean>(false)
  const [severity, setSeverity] = React.useState<string>('')
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('')

  const [totalPagesIndicators, setTotalPageIndicators] =
    React.useState<number>(1)
  const [pageIndicators, setPageIndicators] = React.useState<number>(0)

  async function createIndicator(token: string | null, formData: any) {
    try {
      setLoading(true)
      const { url, options } = CREATE_INDICATOR_POST(token, formData)
      const response = await fetch(url, options)
      if (response.ok) {
        setSeverity('success')
        setFeedbackMessage('Indicador criado com sucesso!')
        setOpen(true)
        getIndicatorsWithFilter(token, false, null)
        setTimeout(() => {
          navigate('/home/configuracoes/indicadores')
        }, 2000)
      } else {
        const body = await response.json()
        console.log(JSON.stringify(body))
        throw new Error(body.erro)
      }
    } catch (error) {
      setErrorMessage(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function editIndicator(
    token: string | null,
    id: number,
    formData: any
  ) {
    try {
      setLoading(true)
      const { url, options } = await EDIT_INDICATOR_PUT(token, id, formData)
      const response = await fetch(url, options)
      if (response.ok) {
        setSeverity('success')
        setFeedbackMessage('Indicador atualizado com sucesso!')
        setOpen(true)
        getIndicatorsWithFilter(token, false, null)
        setTimeout(() => {
          navigate('/home/configuracoes/indicadores')
        }, 2000)
      } else {
        const body = await response.json()

        throw new Error(body.erro)
      }
    } catch (error) {
      setErrorMessage(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function deleteIndicator(token: string | null, id: number) {
    try {
      const { url, options } = DELETE_INDICATOR_DEL(token, id)
      const response = await fetch(url, options)
      const json = await response.json()
      if (response.ok) {
        setSeverity('success')
        setFeedbackMessage(json.message)
        setOpen(true)
        getIndicatorsWithFilter(token, false, null)
      } else {
        throw new Error(`${response.status}`)
      }
    } catch (err) {
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    }
  }

  async function listIndicatorsByWorkspace(
    token: string | null,
    workspaceId: number | null
  ) {
    false
  }

  async function getIndicatorsWithFilter(
    token: string | null,
    increment: boolean,
    filter: string | null
  ) {
    try {
      if (increment) {
        if (pageIndicators + 1 < totalPagesIndicators)
          setPageIndicators((pageIndicators ? pageIndicators : 0) + 1)
      } else if (!increment) {
        setPageIndicators(0)
      }

      //Não deve atualizar caso o numero de páginas seja maior que o numero de total retornado pelo server
      if (totalPagesIndicators < pageIndicators) {
        setPageIndicators(totalPagesIndicators)
      }

      const { url, options } = LIST_ALL_INDICATOR_GET_WITH_FILTER(
        token,
        pageIndicators,
        filter
      )
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      const { content, totalPages } = await response.json()
      setTotalPageIndicators(totalPages)
      setIndicators(content)
    } catch (err) {
      setErrorMessage('Erro ao receber os indicadores ativos.')
    }
  }

  async function setIndicator(formData) {
    setIndicatorData(formData)
  }

  const value = {
    indicatorData,
    indicators,
    loading,
    errorMessage,
    open,
    setOpen,
    severity,
    feedbackMessage,
    createIndicator,
    editIndicator,
    deleteIndicator,
    getIndicatorsWithFilter,
    listIndicatorsByWorkspace,
    setIndicatorData,
    setIndicator
  }

  return (
    <IndicatorContext.Provider value={value}>
      {children}
    </IndicatorContext.Provider>
  )
}

export function useIndicatorContext() {
  const context = useContext(IndicatorContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useIndicatorContext must be used within an IndicatorContext'
    )
  }

  return context
}

import { Navigate, Route } from 'react-router-dom'
import { useUserContext } from '../Data/UserContext'

function RotaProtegida({ element, path }) {
  const { login } = useUserContext()
  const ele =
    login === true ? element : <Navigate to="/" replace state={{ path }} />

  return <Route path={path} element={ele} />
}

export default RotaProtegida

export default {
  font: {
    family: "'Open Sans', sans-serif",
    light: 300,
    normal: 400,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    sizes: {
      hxx: '5.2rem',
      hx: '3.6rem',
      h1: '1.5rem',
      h2: '1rem',
      h3: '2rem',
      body: '1rem',
      button: '1rem',
      caption: '1.2rem'
    }
  },
  colors: {
    powerbi: {
      primary: '#2B45D4', // azul
      secondary: '#FFCC00', // amarelo
      ascent: '#FF0073', // rosa
      lightgray: '#F2F2F2'
    },
    gray: 'rgba(0, 0, 0, 0.56)',
    black: 'rgba(0, 0, 0, 0.88)'
    // info: {
    //   light: '#009AEB',
    //   medium: '#008AD2',
    //   dark: '#0079B8'
    // },
    // success: {
    //   light: '#40CF54',
    //   medium: '#38B449',
    //   dark: '#309C3F'
    // },
    // alert: {
    //   light: '#FFC61A',
    //   medium: '#FFB300',
    //   dark: '#E5A100'
    // },
    // danger: {
    //   light: '#FF584C',
    //   medium: '#F44336',
    //   dark: '#DB3C31'
    // }
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  }
}

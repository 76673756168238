import * as React from 'react'

import './Switch.css'
import cx from 'classnames'

type SwitchProps = {
  rounded: boolean
  isToggled?: boolean
  onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch = ({ rounded = true, isToggled, onToggle }: SwitchProps) => {
  const sliderCX = cx('slider', {
    rounded: rounded
  })
  return (
    <label className="switch">
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className={sliderCX} />
    </label>
  )
}

export default Switch

import { makeStyles } from '@material-ui/styles'
import styled, { css } from 'styled-components'
import React, { useState, useEffect } from 'react'
import MaidaBtn from 'components/MaidaBtn'
import { useUserContext } from 'components/Data/UserContext'
import NewSnackbar from 'components/NewSnackbar'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { ContainerInputs } from './Configurations/Users/CreateUser'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import ItemListAcessos from './Configurations/ItemListAcessos'
import { UserProps } from 'components/Data/Types/UserType'
import { convertToObject } from 'typescript'
import SearchBar from './Configurations/SearchBar'
import { useAppContext } from 'components/Data/AppContext'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '50%',
    paddingBottom: '24px'
  }
})
const token = sessionStorage.getItem('token')

const AcessosPorRelatorio = () => {
  const yestarday = new Date()
  yestarday.setDate(yestarday.getDate() - 1)

  const classes = useStyles()
  const {
    token,
    loading,
    open,
    feedbackMessage,
    severity,
    setOpen,
    usersNoAdmin,
    getUsersWithFilterNoAdmin
  } = useUserContext()
  const { workspaces, listAllWorkspaces } = useWorkspaceContext()
  const { filterSearch, setFilter } = useAppContext()

  const [usersFiltered, setUsersFiltered] = React.useState<any[]>([])
  const [workspace, setWorkspace] = React.useState<any>({})
  const [errorUser, setErrorUser] = React.useState([false, ''])

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  const [scrollY, setScrollY] = useState(0)

  function changeScrollY() {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', changeScrollY)
    }
    watchScroll()
    return () => {
      window.removeEventListener('scroll', changeScrollY)
    }
  })

  useEffect(() => {
    if (token) {
      if (filterSearch) {
        getUsersWithFilterNoAdmin(token, false, filterSearch)
      } else {
        getUsersWithFilterNoAdmin(token, false, '')
      }
    }
  }, [filterSearch])

  useEffect(() => {
    if (token) {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.scrollingElement?.scrollHeight
      ) {
        if (filterSearch) {
          getUsersWithFilterNoAdmin(token, true, filterSearch)
        } else {
          getUsersWithFilterNoAdmin(token, true, '')
        }
      }
    }
  }, [scrollY])

  const filterUsers = () => {
    setUsersFiltered(usersNoAdmin)
  }

  const haveAccess = (u) => {
    // console.log(u.workspaces.find((e) => e === workspace?.name))
    return u.workspaces.find((e) => e === workspace?.name) ? true : false
  }

  return (
    <form noValidate>
      <ContainerInputs>
        <FormControl className={classes.input}>
          <InputLabel id="acesso" sx={{ fontSize: '16px' }}>
            Indicador
          </InputLabel>
          <Select
            variant="outlined"
            sx={{
              '& .MuiSelect-icon': { color: '#FF0073', fontSize: '26px' },
              '& .MuiSelect-select': {
                color: '#000000E0'
              },
              '& .MuiSelect-outlined': {
                fontSize: '16px'
              }
            }}
            label="Indicador"
            name="acesso"
            id="acesso"
            value={workspace}
            onChange={(e) => {
              setWorkspace(e.target.value)

              filterUsers()
            }}
          >
            {workspaces.map((option) => (
              <MenuItem
                style={{
                  fontSize: '16px',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-start',
                  margin: '5px 10px'
                }}
                key={option.id}
                value={option}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ContainerInputs>
      <WrapperSearch>
        <SearchBar />
      </WrapperSearch>
      <DataListWrapper>
        <ul>
          {usersNoAdmin &&
            usersNoAdmin.map((u, id) => {
              return (
                <ItemListAcessos
                  usuario={u}
                  key={id}
                  idItem={u.id}
                  workspace={workspace}
                  title={u.name}
                  active={u.active}
                  description={u.createdAt}
                  icon={u.iconUrl}
                  haveAccess={haveAccess(u)}
                />
              )
            })}
        </ul>
      </DataListWrapper>
      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </form>
  )
}

const WrapperSearch = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;

  @media (max-width: 930px) {
    margin-top: 30px;
  }
`

const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    border: none;
    background-color: ${theme.colors.powerbi.secondary};
    color: #000000e0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    border-radius: 1000px;
    cursor: pointer;
    min-width: 180px;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &.marginTop {
      margin-top: 40px;
    }
  `}
`

const DataListWrapper = styled.div`
  display: block;
`

export default AcessosPorRelatorio

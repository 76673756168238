import { makeStyles } from '@material-ui/styles'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import { useAppContext } from 'components/Data/AppContext'
import { useUserContext } from 'components/Data/UserContext'
import MaidaBtn from 'components/MaidaBtn'
import NewSnackbar from 'components/NewSnackbar'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import ContainerLogin from '../Login/ContainerLogin'
import {
  Banner,
  Logo,
  ContainerDireita,
  LoginContainer,
  Header,
  Container
} from '../Login/styles'
import { Botao, ErrorSenha } from './Configurations/Users/CreateUser'
import * as S from './styles'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '50%',
    paddingBottom: '24px'
  }
})

type UserAPI = {
  name: string
  email: string
  password: string
  role: string
  workspaces: Array<string>
  active: boolean
  microsoftEmail: string
  googleEmail: string
}

const RedefinirSenha = () => {
  const classes = useStyles()
  const [loginMethod, setLoginMethod] = React.useState(false)
  const {
    error,
    open,
    setOpen,
    severity,
    feedbackMessage,
    changeForgetedPassword
  } = useUserContext()
  const [loading, setLoading] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [newPasswordAgain, setNewPasswordAgain] = useState('')
  const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false)
  const [errorNewPassword, setErrorNewPassword] = React.useState([false, ''])
  const [errorNewPasswordAgain, setErrorNewPasswordAgain] = React.useState([
    false,
    ''
  ])

  const [searchParams, setSearchParams] = useSearchParams()

  const validatePassword = (valor, typePassword) => {
    if (valor.length === 0) {
      switch (typePassword) {
        case 0:
          setErrorNewPassword([true, 'Preencha uma senha.'])
          break
        case 1:
          setErrorNewPasswordAgain([true, 'Preencha uma senha.'])
          break
      }
      return false
    } else if (valor.length >= 100) {
      switch (typePassword) {
        case 0:
          setErrorNewPassword([
            true,
            'A senha precisa ter menos de 50 caracteres.'
          ])
          break
        case 1:
          setErrorNewPasswordAgain([
            true,
            'A senha precisa ter menos de 50 caracteres.'
          ])
          break
      }
      return false
    } else if (valor.length <= 3) {
      switch (typePassword) {
        case 0:
          setErrorNewPassword([
            true,
            'A senha precisa ter no mínimo 4 caracteres.'
          ])
          break
        case 1:
          setErrorNewPasswordAgain([
            true,
            'A senha precisa ter no mínimo 4 caracteres.'
          ])
          break
      }
      return false
    } else {
      switch (typePassword) {
        case 0:
          setErrorNewPassword([false, ''])
          break
        case 1:
          setErrorNewPasswordAgain([false, ''])
          break
      }
      return true
    }
  }

  const handleSetNewPassword = (e) => {
    if (errorNewPassword[0]) validatePassword(e.target.value, 0)
    setNewPassword(e.target.value)
  }

  const handleChangeNewPassword = (e) => {
    validatePassword(e.target.value, 0)
  }

  const handleClickShowNewPassword = (e) => {
    setShowNewPassword(!showNewPassword)
  }

  const handleSetNewPasswordAgain = (e) => {
    if (errorNewPasswordAgain[0]) validatePassword(e.target.value, 1)
    setNewPasswordAgain(e.target.value)
  }

  const handleChangeNewPasswordAgain = (e) => {
    validatePassword(e.target.value, 1)
  }

  const handleClickShowNewPasswordAgain = (e) => {
    setShowNewPasswordAgain(!showNewPasswordAgain)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (newPassword != newPasswordAgain) {
      setErrorNewPasswordAgain([true, 'As senhas não estão iguais.'])
    } else {
      setErrorNewPasswordAgain([false, ''])

      console.log(searchParams.get('key'))

      const UsuarioAPI = {
        temporarilyToken: searchParams.get('key'),
        password: newPassword
      }

      if (UsuarioAPI) {
        const token = window.localStorage.getItem('token')
        changeForgetedPassword(UsuarioAPI)
      }
    }
  }

  return (
    <Container>
      <Banner>
        <Logo />
      </Banner>
      <ContainerDireita>
        <LoginContainer>
          <Header>
            <h1>Redefinir Senha</h1>
          </Header>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: '14px'
            }}
          >
            <form
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              onSubmit={handleSubmit}
            >
              <InputLabel
                htmlFor="senha"
                style={{ marginTop: 20, fontSize: '16px' }}
              >
                Senha Nova
              </InputLabel>
              <OutlinedInput
                name="senha"
                required
                inputProps={{
                  style: { fontSize: '16px' }
                }}
                id="senha"
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={handleSetNewPassword}
                onBlur={handleChangeNewPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: '#FF0073' }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={() => {
                        return null
                      }}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {errorNewPassword[0] && (
                <ErrorSenha>{errorNewPassword[1]}</ErrorSenha>
              )}
              <InputLabel
                htmlFor="senha"
                style={{ marginTop: 20, fontSize: '16px' }}
              >
                Repita a Senha Nova
              </InputLabel>
              <OutlinedInput
                name="senha"
                required
                inputProps={{
                  style: { fontSize: '16px' }
                }}
                id="senha"
                type={showNewPasswordAgain ? 'text' : 'password'}
                value={newPasswordAgain}
                onChange={handleSetNewPasswordAgain}
                onBlur={handleChangeNewPasswordAgain}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: '#FF0073' }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPasswordAgain}
                      onMouseDown={() => {
                        return null
                      }}
                      edge="end"
                    >
                      {showNewPasswordAgain ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {errorNewPasswordAgain[0] && (
                <ErrorSenha>{errorNewPasswordAgain[1]}</ErrorSenha>
              )}
              {error && (
                <p
                  style={{ marginTop: '10px', color: 'red', fontSize: '14px' }}
                >
                  {error}
                </p>
              )}
              <Botao>
                {loading ? (
                  <MaidaBtn text="Carregando ..." disabled={true} />
                ) : (
                  <MaidaBtn
                    text="Salvar"
                    disabled={newPassword && newPasswordAgain ? false : true}
                    onClick={handleSubmit}
                    type="submit"
                  ></MaidaBtn>
                )}
              </Botao>
              <NewSnackbar
                open={open}
                setOpen={setOpen}
                severity={severity}
                message={feedbackMessage}
              />
            </form>
          </div>
        </LoginContainer>
      </ContainerDireita>
    </Container>
  )
}

const InputBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`
const Error = styled.p`
  font-size: 14px;
  padding-top: 6px;
  color: red;
`

const Input = styled.input`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 12px 20px 12px 56px;
    border: 2px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.black};
    font-family: 'Open Sans', sans-serif;

    &:focus {
      border: 2px solid ${theme.colors.powerbi.primary};
      outline: none;
    }
    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
    }

    &.marginBottom {
      margin-bottom: 16px;
    }
  `}
`

const BackButton = styled.span`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: -90px;
  left: -10px;
`

export default RedefinirSenha

export const configMicrosoft = {
  appId: '8b6b2d36-3206-4044-a612-8212056a4ad2',
  redirectUri: 'https://analytics.maida.health',
  scopes: ['User.Read'],
  authority:
    'https://login.microsoftonline.com/a5b42926-1763-4a5c-9244-93af22b890d3'
}

export const configGoogle = {
  clientId:
    '826395169840-s9c6n7ctsma4lcfdh7oaagnnjqio3lr5.apps.googleusercontent.com',
  clientSecret: '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'
}

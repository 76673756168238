import { App } from 'App'
import { ThemeProvider } from 'styled-components'

import React from 'react'
import ReactDOM from 'react-dom'
import GlobalStyles from 'styles/global'
import theme from 'styles/theme'
import reportWebVitals from './reportWebVitals'

//Microsoft Login
import { Providers } from '@microsoft/mgt-element'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'

//Configurações de login
import { configMicrosoft } from './appsettings'

Providers.globalProvider = new Msal2Provider({
  clientId: configMicrosoft.appId
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { makeStyles } from '@material-ui/styles'
import {
  Link,
  Card,
  CardActions,
  Button,
  Avatar,
  CardHeader,
  Modal
} from '@mui/material'
import { useUserContext } from 'components/Data/UserContext'
import React, { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Logo from '../../../assets/imgs/perfil-Icon.svg'
import UserLogo from '../../../assets/imgs/mai-ic-user.mono.svg'
import LogoutLogo from '../../../assets/imgs/mai-ic-logout.mono.svg'
import PasswordLogo from '../../../assets/imgs/mai-ic-password.mono.svg'
import Back from './Back'
import * as S from './styles'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import Perfil from './Perfil'
import RedefinirSenha from './RedefinirSenha'

const Header = () => {
  const navigate = useNavigate()
  const params = useParams()
  const URLparams = String(Object.values(params))
  const { userData, currentUser, userLogout } = useUserContext()
  const { currentWorkspace } = useWorkspaceContext()
  const [isMenuOpen, setMenuOpen] = useState(false)

  //Estilização do modal perfil
  const useStyles = makeStyles({
    modal: {
      margin: 0,
      top: 50,
      right: 140,
      botton: 'auto',
      left: 'auto',
      position: 'fixed'
    }
  })
  const classes = useStyles()

  if (URLparams.includes('resultadoIndicador')) return <Back link="/home/0" />
  switch (URLparams) {
    case 'configuracoes/usuarios':
      return <h1>Usuários</h1>
    case 'configuracoes/usuarios/criarUsuario':
      return (
        <>
          <Back link="configuracoes/usuarios" />
          {currentUser ? <h1>Editar usuário</h1> : <h1>Novo usuário</h1>}
        </>
      )
    case 'configuracoes/usuarios/criarUsuarioCSV':
      return (
        <>
          <Back link="configuracoes/usuarios" />
          <h1>Cadastrar do CSV</h1>
        </>
      )
    case 'configuracoes/usuarios/acessosByReport':
      return (
        <>
          <Back link="configuracoes/usuarios" />
          <h1>Acessos por Relatório</h1>
        </>
      )
    case 'configuracoes/relatorios':
      return <h1>Relatórios</h1>
    case 'configuracoes/relatorios/criarRelatorio':
      return (
        <>
          <Back link="configuracoes/relatorios" />
          {<h1>Novo relatório</h1>}
        </>
      )
    case 'configuracoes/relatorios/editarRelatorio':
      return (
        <>
          <Back link="configuracoes/relatorios" />
          {<h1>Editar relatório</h1>}
        </>
      )
    case 'configuracoes/indicadores':
      return <h1>Indicadores</h1>
    case 'configuracoes/indicadores/criarIndicador':
      return (
        <>
          <Back link="configuracoes/indicadores" />
          <h1>Novo indicador</h1>
        </>
      )
    case 'configuracoes/indicadores/editarIndicador':
      return (
        <>
          <Back link="configuracoes/indicadores" />
          <h1>Editar indicador</h1>
        </>
      )
    case 'perfil':
      return (
        <>
          <Back link="/home" />
          <h1>Perfil</h1>
        </>
      )
    case 'trocarSenha':
      return (
        <>
          <Back link="/home" />
          <h1>Trocar Senha</h1>
        </>
      )
    case 'configuracoes/auditoria':
      return <h1>Auditoria</h1>
    case 'configuracoes/embedded':
      return <h1>Gerenciamento do Embedded (Azure)</h1>
    case 'configuracoes/embedded/criarEmbedded':
      return (
        <>
          <Back link="configuracoes/embedded" />
          <h1>Novo Serviço Embedded</h1>
        </>
      )
    case 'configuracoes/embedded/managerEmbedded':
      return (
        <>
          <Back link="configuracoes/embedded" />
          <h1>Manutenção do Serviço Embedded</h1>
        </>
      )
    case 'configuracoes/relatorios/acessosByReport':
      return (
        <>
          <Back link="configuracoes/relatorios" />
          <h1>Acessos por Relatório</h1>
        </>
      )
    case 'auth/recoverPassword':
      return <RedefinirSenha />
    default:
      return (
        <header>
          <S.HeaderPages>
            <S.Titulo>
              <h1>{`Olá, ${
                userData ? userData['name'] : 'Usuário(a) da Maida'
              }`}</h1>
            </S.Titulo>
            <span>
              <Link
                component="button"
                onClick={() => {
                  setMenuOpen(!isMenuOpen)
                }}
              >
                <S.IconPerfil src={Logo} alt="Perfil" />
              </Link>
              {isMenuOpen && (
                <React.Fragment>
                  <Modal
                    className={classes.modal}
                    open={isMenuOpen}
                    onClose={() => {
                      setMenuOpen(!isMenuOpen)
                    }}
                  >
                    <Card>
                      {/* Card interno */}
                      <CardHeader
                        avatar={
                          <Avatar>{`${
                            userData
                              ? String(userData['name'])
                                  .substring(0, 1)
                                  .toUpperCase()
                              : ''
                          }`}</Avatar>
                        }
                        title={`${userData ? userData['name'] : ''}`}
                        subheader={`${userData ? userData['email'] : ''}`}
                      />
                      <CardActions title="Perfil">
                        <Button
                          color="inherit"
                          startIcon={
                            <S.IconPerfilInterno src={UserLogo} alt="Perfil" />
                          }
                          onClick={() => {
                            setMenuOpen(!isMenuOpen)
                            navigate('/home/perfil')
                          }}
                        >
                          Perfil
                        </Button>
                      </CardActions>
                      <CardActions title="Perfil">
                        <Button
                          color="inherit"
                          startIcon={
                            <S.IconPerfilInterno
                              src={PasswordLogo}
                              alt="Trocar Senha"
                            />
                          }
                          onClick={() => {
                            setMenuOpen(!isMenuOpen)
                            navigate('/home/trocarSenha')
                          }}
                        >
                          Trocar a Senha
                        </Button>
                      </CardActions>
                      <CardActions title="Perfil">
                        <Button
                          color="inherit"
                          startIcon={
                            <S.IconPerfilInterno src={LogoutLogo} alt="Sair" />
                          }
                          onClick={() => userLogout()}
                        >
                          Sair
                        </Button>
                      </CardActions>
                    </Card>
                  </Modal>
                </React.Fragment>
              )}
            </span>
          </S.HeaderPages>
        </header>
      )
    // if (userData) {
    //   return <h1>Olá, {userData['name']}</h1>
    // } else {
    //   return <h1>Olá, Usuário(a) da Maida</h1>
    // }
  }
}

export default Header

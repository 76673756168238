import { makeStyles } from '@material-ui/styles'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import { useAppContext } from 'components/Data/AppContext'
import { useUserContext } from 'components/Data/UserContext'
import MaidaBtn from 'components/MaidaBtn'
import NewSnackbar from 'components/NewSnackbar'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Botao, ErrorSenha } from './Configurations/Users/CreateUser'
import * as S from './styles'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '50%',
    paddingBottom: '24px'
  }
})

type UserAPI = {
  name: string
  email: string
  password: string
  role: string
  workspaces: Array<string>
  active: boolean
  microsoftEmail: string
  googleEmail: string
}

const TrocarSenha = () => {
  const classes = useStyles()
  const { userData, changePassword, open, setOpen, severity, feedbackMessage } =
    useUserContext()
  const [loading, setLoading] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [newPasswordAgain, setNewPasswordAgain] = useState('')
  const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false)
  const [errorOldPassword, setErrorOldPassword] = React.useState([false, ''])
  const [errorNewPassword, setErrorNewPassword] = React.useState([false, ''])
  const [errorNewPasswordAgain, setErrorNewPasswordAgain] = React.useState([
    false,
    ''
  ])

  const validatePassword = (valor, typePassword) => {
    if (valor.length === 0) {
      switch (typePassword) {
        case 0:
          setErrorOldPassword([true, 'Preencha uma senha.'])
          break
        case 1:
          setErrorNewPassword([true, 'Preencha uma senha.'])
          break
        case 2:
          setErrorNewPasswordAgain([true, 'Preencha uma senha.'])
          break
      }
      return false
    } else if (valor.length >= 100) {
      switch (typePassword) {
        case 0:
          setErrorOldPassword([
            true,
            'A senha precisa ter menos de 50 caracteres.'
          ])
          break
        case 1:
          setErrorNewPassword([
            true,
            'A senha precisa ter menos de 50 caracteres.'
          ])
          break
        case 2:
          setErrorNewPasswordAgain([
            true,
            'A senha precisa ter menos de 50 caracteres.'
          ])
          break
      }
      return false
    } else if (valor.length <= 3) {
      switch (typePassword) {
        case 0:
          setErrorOldPassword([
            true,
            'A senha precisa ter no mínimo 4 caracteres.'
          ])
          break
        case 1:
          setErrorNewPassword([
            true,
            'A senha precisa ter no mínimo 4 caracteres.'
          ])
          break
        case 2:
          setErrorNewPasswordAgain([
            true,
            'A senha precisa ter no mínimo 4 caracteres.'
          ])
          break
      }
      return false
    } else {
      switch (typePassword) {
        case 0:
          setErrorOldPassword([false, ''])
          break
        case 1:
          setErrorNewPassword([false, ''])
          break
        case 2:
          setErrorNewPasswordAgain([false, ''])
          break
      }
      return true
    }
  }

  const handleSetOldPassword = (e) => {
    if (errorOldPassword[0]) validatePassword(e.target.value, 0)
    setOldPassword(e.target.value)
  }

  const handleChangeOldPassword = (e) => {
    validatePassword(e.target.value, 0)
  }

  const handleClickShowOldPassword = (e) => {
    setShowOldPassword(!showOldPassword)
  }

  const handleSetNewPassword = (e) => {
    if (errorNewPassword[0]) validatePassword(e.target.value, 1)
    setNewPassword(e.target.value)
  }

  const handleChangeNewPassword = (e) => {
    validatePassword(e.target.value, 1)
  }

  const handleClickShowNewPassword = (e) => {
    setShowNewPassword(!showNewPassword)
  }

  const handleSetNewPasswordAgain = (e) => {
    if (errorNewPasswordAgain[0]) validatePassword(e.target.value, 2)
    setNewPasswordAgain(e.target.value)
  }

  const handleChangeNewPasswordAgain = (e) => {
    validatePassword(e.target.value, 2)
  }

  const handleClickShowNewPasswordAgain = (e) => {
    setShowNewPasswordAgain(!showNewPasswordAgain)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (newPassword != newPasswordAgain) {
      setErrorNewPasswordAgain([true, 'As senhas não estão iguais.'])
    } else {
      setErrorNewPasswordAgain([false, ''])

      const UsuarioAPI: UserAPI = {
        name: userData?.name ? userData?.name : '',
        email: userData?.email ? userData?.email : '',
        active: userData?.active ? userData?.active : true,
        password: newPassword,
        role: userData?.roles ? userData?.roles[0] : '',
        workspaces: userData?.workspaces ? userData?.workspaces : [],
        microsoftEmail: userData?.microsoftEmail
          ? userData?.microsoftEmail
          : '',
        googleEmail: userData?.googleEmail ? userData?.googleEmail : ''
      }

      if (UsuarioAPI) {
        const token = window.localStorage.getItem('token')
        changePassword(token, UsuarioAPI, Number(userData?.id))
      }
    }
  }

  return (
    <div>
      <section>
        <span className="silver">
          Preencha os campos abaixo para redefinição de senha
        </span>
      </section>
      <Container>
        <InputLabel htmlFor="senha" style={{ fontSize: '16px' }}>
          Senha Antiga
        </InputLabel>
        <OutlinedInput
          name="senha"
          required
          inputProps={{
            style: { fontSize: '16px' }
          }}
          id="senha"
          type={showOldPassword ? 'text' : 'password'}
          value={oldPassword}
          onChange={handleSetOldPassword}
          onBlur={handleChangeOldPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                style={{ color: '#FF0073' }}
                aria-label="toggle password visibility"
                onClick={handleClickShowOldPassword}
                onMouseDown={() => {
                  return null
                }}
                edge="end"
              >
                {showOldPassword ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        {errorOldPassword[0] && <ErrorSenha>{errorOldPassword[1]}</ErrorSenha>}
        <InputLabel htmlFor="senha" style={{ marginTop: 20, fontSize: '16px' }}>
          Senha Nova
        </InputLabel>
        <OutlinedInput
          name="senha"
          required
          inputProps={{
            style: { fontSize: '16px' }
          }}
          id="senha"
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={handleSetNewPassword}
          onBlur={handleChangeNewPassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                style={{ color: '#FF0073' }}
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPassword}
                onMouseDown={() => {
                  return null
                }}
                edge="end"
              >
                {showNewPassword ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        {errorNewPassword[0] && <ErrorSenha>{errorNewPassword[1]}</ErrorSenha>}
        <InputLabel htmlFor="senha" style={{ marginTop: 20, fontSize: '16px' }}>
          Repita a Senha Nova
        </InputLabel>
        <OutlinedInput
          name="senha"
          required
          inputProps={{
            style: { fontSize: '16px' }
          }}
          id="senha"
          type={showNewPasswordAgain ? 'text' : 'password'}
          value={newPasswordAgain}
          onChange={handleSetNewPasswordAgain}
          onBlur={handleChangeNewPasswordAgain}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                style={{ color: '#FF0073' }}
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPasswordAgain}
                onMouseDown={() => {
                  return null
                }}
                edge="end"
              >
                {showNewPasswordAgain ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
        {errorNewPasswordAgain[0] && (
          <ErrorSenha>{errorNewPasswordAgain[1]}</ErrorSenha>
        )}
      </Container>
      <Botao>
        {loading ? (
          <MaidaBtn text="Carregando ..." disabled={true} />
        ) : (
          <MaidaBtn
            text="Salvar"
            disabled={
              oldPassword && newPassword && newPasswordAgain ? false : true
            }
            onClick={handleSubmit}
            type="submit"
          ></MaidaBtn>
        )}
      </Botao>
      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </div>
  )
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

export default TrocarSenha

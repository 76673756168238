import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const Back = ({ link }) => {
  return (
    <Link to={link} className="back">
      <ArrowBackIosIcon style={{ fill: '#FF0073' }} />
      Voltar
    </Link>
  )
}

export default Back

import styled, { css } from 'styled-components'

const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 14px 0;
    border: none;
    background-color: ${theme.colors.powerbi.secondary};
    color: #000000e0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    border-radius: 1000px;
    cursor: pointer;
    min-width: 180px;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  `}
`

interface BtnProps {
  text: string
  disabled?: boolean
  type?: any
  className?: string
  onClick?: any
}

const MaidaBtn = ({ text, disabled, type, className, onClick }: BtnProps) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      className={className}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

export default MaidaBtn

import { API_URL } from './api'

export function USER_GET(token) {
  return {
    url: API_URL + '/user/me',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function USER_GET_BY_USER(token, body) {
  return {
    url: API_URL + '/user/getByUsername',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

export function GET_USERS(token, page, filter) {
  return {
    url: `${API_URL}/user?${filter ? `filter=${filter}&` : ''}page=${Number(
      page
    )}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function GET_USERS_NO_ADMIN(token, page, filter) {
  return {
    url: `${API_URL}/user/noAdmin?${
      filter ? `filter=${filter}&` : ''
    }page=${Number(page)}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function CREATE_USER_POST(token, body) {
  return {
    url: API_URL + '/user',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

export function EDIT_USER_PUT(token, body, id) {
  return {
    url: API_URL + `/user/${id}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

export function EDIT_PERFIL_USER_PUT(token, body, id) {
  return {
    url: API_URL + `/user/editPerfil/${id}`,
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

export function DELETE_USER(token, id) {
  return {
    url: API_URL + `/user/${id}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

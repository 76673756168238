import React from 'react'
import FormControl from '@mui/material/FormControl'
import Button from '@material-ui/core/Button'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useUserContext } from 'components/Data/UserContext'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { Input, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import styled from 'styled-components'
import IconDownload from '../IconDownload'
import createStyles from '@material-ui/core/styles/createStyles'
import { DataGrid } from '@material-ui/data-grid'
import { Titulo } from '../../styles'
import { OutlinedInput } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: { width: '100%', marginTop: 20 },
    inputText: {
      fontSize: '16px'
    },
    text: {
      width: '100%',
      marginRight: 10,
      paddingLeft: 10,
      marginBottom: 20,
      borderWidth: 2,
      borderRadius: 10,
      borderColor: '#000'
    },
    input: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-around',
      marginBottom: 10
    },
    input2: {
      width: '100%',
      marginBottom: '20px'
    },
    table: {
      marginTop: 10,
      width: '100%',
      height: '500px',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  })
)

type UserAPI = {
  name: string
  email: string
  password: string
  role: string
  workspaces: Array<string>
  active: boolean
}

const CreateCSVUser = () => {
  const classes = useStyles()
  const {
    createUserByCSV,
    loading,
    roles,
    workspacesUserSelect,
    setWorkspacesUserSelect
  } = useUserContext()
  const { workspaces } = useWorkspaceContext()

  //File
  const [acesso, setAcesso] = React.useState('')
  const [isIntegrarGoogleMicrosoft, setIntegrarGoogleMicrosoft] =
    React.useState(true)
  const [csvFile, setCsvFile] = React.useState<any | undefined | null>()
  const [csvArray, setCsvArray] = React.useState<any | undefined | null>([])
  const [csvTitles, setCsvTitles] = React.useState([])

  //Errors
  const [errorArquivo, setErrorArquivo] = React.useState([false, ''])

  const handleSubmit = (e) => {
    e.preventDefault()
    const token = window.localStorage.getItem('token')
    if (csvArray && csvArray.length > 0) {
      csvArray.forEach(async (v) => {
        if (v && v.email) {
          const UsuarioAPI: UserAPI = {
            name: v.nome,
            email: v.email,
            active: true,
            password: '123456@Temp',
            role: acesso,
            workspaces: workspacesUserSelect
          }

          if (UsuarioAPI) {
            const ret = await createUserByCSV(token, UsuarioAPI)
            console.log(JSON.stringify(ret))
            v.status = ret
          }
        }
      })
    }

    setCsvArray(csvArray)
  }

  const processCSV = (str, delim = ',') => {
    const headers = str.slice(0, str.indexOf('\n')).split(delim)
    const rows = str.slice(str.indexOf('\n') + 1).split('\n')
    const senha = str
      .slice(str.indexOf('\n') + 1)
      .split('\n')[1]
      .split(delim)

    const newTitles = headers.map((row) => {
      return {
        field: row.split(delim)[0].toUpperCase().includes('SENHA')
          ? 'Status'.toLowerCase()
          : row.split(delim)[0].toLowerCase(),
        headerName: row.split(delim)[0].toUpperCase().includes('SENHA')
          ? 'Status'.toUpperCase()
          : row.split(delim)[0].toUpperCase(),
        width:
          row.split(delim)[0].toLowerCase() == 'nome'
            ? 300
            : row.split(delim)[0].toLowerCase() == 'email'
            ? 300
            : 150,
        editable: false
      }
    })

    const newArray = rows.map((row, index) => {
      const values = row.split(delim)
      const eachObject = headers.reduce(
        (obj, header, i) => {
          obj[header.toLowerCase()] = i >= values.length ? '' : values[i]
          return obj
        },
        { id: index, status: '--' }
      )
      return eachObject
    })
    setCsvTitles(newTitles)
    setCsvArray(newArray)
  }

  const handleReadFile = () => {
    const file = csvFile
    const reader = new FileReader()

    reader.onload = (e) => {
      const text = e.target?.result
      processCSV(text)
    }
    reader.readAsText(file)
  }

  const handleChangeCheckBox = ({ target }) => {
    if (target.checked) {
      setWorkspacesUserSelect([...workspacesUserSelect, target.value])
    } else {
      setWorkspacesUserSelect(
        workspacesUserSelect.filter((itemValue) => itemValue !== target.value)
      )
    }
  }

  React.useEffect(() => {
    if (csvFile) handleReadFile()
  }, [csvFile])

  React.useEffect(() => {
    false
  }, [csvArray])

  return (
    <form noValidate>
      <ContainerInputs>
        <FormControl
          className={classes.input2}
          style={{ paddingRight: '24px' }}
        >
          <InputLabel
            id="acesso"
            sx={{
              fontSize: '16px'
            }}
          >
            Nível de Acesso
          </InputLabel>
          <Select
            variant="outlined"
            sx={{
              '& .MuiSelect-icon': { color: '#FF0073', fontSize: '26px' },
              '& .MuiSelect-select': {
                color: '#000000E0'
              },
              '& .MuiSelect-outlined': {
                fontSize: '16px',
                padding: '10px'
              }
            }}
            label="Nível de Acesso"
            name="acesso"
            id="acesso"
            value={acesso}
            onChange={(e) => setAcesso(e.target.value)}
          >
            {roles.map((option) => (
              <MenuItem
                style={{
                  fontSize: '16px',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-start',
                  margin: '5px 10px'
                }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ContainerInputs>

      <FormControl className={classes.input2}>
        <Titulo>Quais relatórios ele poderá visualizar?</Titulo>
        <ContainerGroupSelect>
          <WorkspacesBox>
            {workspaces.map((item: any) => {
              return (
                <label className="label" key={item.id}>
                  <input
                    className="input"
                    type="checkbox"
                    checked={workspacesUserSelect.includes(item.name)}
                    value={item.name}
                    onChange={handleChangeCheckBox}
                  />
                  <span className="custom-input">
                    <div className="containerSVG">{item.imageUrl}</div>
                    <p>{item.name}</p>
                  </span>
                </label>
              )
            })}
          </WorkspacesBox>
        </ContainerGroupSelect>
      </FormControl>

      <FormControl className={classes.input}>
        <input
          type="file"
          id="file"
          accept=".csv"
          onChange={(event) => {
            if (event.target.files) {
              setErrorArquivo([false, ''])
              setCsvFile(event.target.files[0])
            } else setErrorArquivo([true, 'Erro no arquivo'])
          }}
          className={classes.text}
        />
      </FormControl>
      {csvArray && csvArray.length > 0 && (
        <FormControl className={classes.table}>
          <DataGrid rows={csvArray} columns={csvTitles} />
        </FormControl>
      )}
      <FormControl className={classes.input}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={acesso && workspacesUserSelect && csvFile ? false : true}
          // startIcon={<IconDownload />}
          onClick={handleSubmit}
        >
          Salvar
        </Button>
      </FormControl>
    </form>
  )
}

const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ContainerGroupSelect = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 24px;
`

const WorkspacesBox = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px 14px;
`

const WrapperButtons = styled.div`
  display: flex;
  @media (max-width: 930px) {
    flex-direction: column;
  }
`
const CsvButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 240px;
  background: #2b45d4;
  border-radius: 8px;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  margin-left: 16px;
  padding: 16px 19px;
  box-sizing: border-box;
  & a {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1020px) {
    flex: 1 1 260px;
  }

  @media (max-width: 930px) {
    margin: 16px 0 0 0;
    flex: 1;
  }
`

export default CreateCSVUser

import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useParams } from 'react-router'
import { useAppContext } from 'components/Data/AppContext'
import { useState } from 'react'
import Select from 'react-select'
import DataList from './DataList'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { useUserContext } from 'components/Data/UserContext'
import { UserProps } from 'components/Data/Types/UserType'
import { IndicatorProps } from 'components/Data/Types/IndicatorType'
import { useIndicatorContext } from 'components/Data/IndicatorsContext'
import { ReportSharp } from '@mui/icons-material'
import { useAuditoriaContext } from 'components/Data/AuditoriaContext'
import { useEmbeddedContext } from 'components/Data/EmbeddedContext'

const acessos = [
  { value: 'Todos', label: 'Todos' },
  { value: 'USER', label: 'User' },
  { value: 'ADMIN', label: 'Admin' }
]

const FilterHeader = () => {
  const { filterSearch, setFilter } = useAppContext()
  const {
    users: Usuarios,
    getUsers,
    getUsersWithFilter,
    userData
  } = useUserContext()
  const { services, listAll: listAllServices } = useEmbeddedContext()
  const { logs, listAll } = useAuditoriaContext()
  const { workspaces } = useWorkspaceContext()
  const { indicators, getIndicatorsWithFilter } = useIndicatorContext()
  const { id } = useParams()
  const token = window.localStorage.getItem('token')

  // Refere-se a usuarios filtrados caso existaƒ
  const [usuariosFiltrados, setUsuariosFiltrados] =
    useState<UserProps[]>(Usuarios)
  const [indicadoresFiltrados, setIndicadoresFiltrados] =
    useState<IndicatorProps[]>(indicators)
  const allReports = workspaces.map((report) => {
    return { value: report.name, label: report.name }
  })

  const [scrollY, setScrollY] = useState(0)

  function changeScrollY() {
    setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', changeScrollY)
    }
    watchScroll()
    return () => {
      window.removeEventListener('scroll', changeScrollY)
    }
  })

  allReports.unshift({ value: 'Todos', label: 'Todos' })

  function handleFilterUserAccess(e) {
    if (e.value !== 'Todos') {
      // setUsuariosFiltrados(Usuarios.filter((user) => user.roles === e.value))
      setUsuariosFiltrados(
        Usuarios.filter((user) => user.roles.includes(e.value.toUpperCase()))
      )
    } else {
      setUsuariosFiltrados(Usuarios)
    }
  }

  function handleUserReports(e) {
    if (e.value !== 'Todos') {
      return setUsuariosFiltrados(
        Usuarios.filter((user) => user.workspaces.includes(e.value))
      )
    } else {
      return setUsuariosFiltrados(Usuarios)
    }
  }

  useEffect(() => {
    if (token) {
      if (filterSearch) {
        if (id === 'usuarios') {
          getUsersWithFilter(token, false, filterSearch)
        } else if (id === 'indicadores') {
          getIndicatorsWithFilter(token, false, filterSearch)
        }
      } else {
        if (id === 'usuarios') {
          getUsers(token, false)
        } else if (id === 'indicadores') {
          getIndicatorsWithFilter(token, false, null)
        }
      }
    }
  }, [filterSearch])

  useEffect(() => {
    setFilter('')
  }, [id])

  useEffect(() => {
    // Ele só vai atribuir usuarios ao vetor se o cara for ADMIN
    if (userData?.roles.includes('ADMIN')) {
      if (token) {
        getUsers(token, false)
      }
    }
  }, [userData, token])

  useEffect(() => {
    if (userData?.roles.includes('ADMIN')) {
      if (token) {
        if (
          window.innerHeight + document.documentElement.scrollTop ===
          document.scrollingElement?.scrollHeight
        ) {
          if (id === 'usuarios') {
            if (filterSearch) {
              getUsersWithFilter(token, true, filterSearch)
            } else {
              getUsers(token, true)
            }
          } else if (id === 'indicadores') {
            if (filterSearch) {
              getIndicatorsWithFilter(token, true, filterSearch)
            } else {
              getIndicatorsWithFilter(token, true, null)
            }
          } else if (id === 'embedded') {
            listAllServices(token, true)
          }
        }
      }
    }
  }, [scrollY])

  useEffect(() => {
    setUsuariosFiltrados(Usuarios)
  }, [Usuarios])

  useEffect(() => {
    setIndicadoresFiltrados(indicators)
  }, [indicators])

  return (
    <>
      <WrapperHeader>
        <div>
          <span className="pink" style={{ marginBottom: '0' }}>
            {id === 'usuarios' &&
              `${Usuarios.length}  usuário(s) cadastrado(s)`}
            {id === 'relatorios' &&
              workspaces.length + ' grupo(s) cadastrado(s)'}
            {id === 'indicadores' &&
              indicators.length + ' indicador(es) cadastrado(s)'}
            {id === 'embedded' &&
              (services ? services.length : 0) + ' serviço(s) cadastrado(s)'}
          </span>
        </div>
        {id === 'usuarios' && (
          <div>
            <SelectorsWrapper>
              <SelectContent>
                <Label>
                  Nível de acesso
                  <SelectStyled
                    options={acessos}
                    placeholder={
                      <SelectNumber>{acessos[0].label}</SelectNumber>
                    }
                    onChange={handleFilterUserAccess}
                    defaultValue={acessos[0].value}
                  />
                </Label>
              </SelectContent>
              <SelectContent>
                <Label>
                  Relatórios
                  <SelectStyled
                    options={allReports}
                    placeholder={
                      <SelectNumber>{allReports[0].label}</SelectNumber>
                    }
                    onChange={handleUserReports}
                    defaultValue={allReports[0].value}
                  />
                </Label>
              </SelectContent>
            </SelectorsWrapper>
          </div>
        )}
      </WrapperHeader>

      {id === 'usuarios' && (
        <DataListWrapper>
          <DataList itens={usuariosFiltrados} />
        </DataListWrapper>
      )}
      {id === 'indicadores' && (
        <DataListWrapper>
          <DataList itens={indicators} />
        </DataListWrapper>
      )}
      {id === 'embedded' && (
        <DataListWrapper>
          <DataList itens={services} />
        </DataListWrapper>
      )}
    </>
  )
}

const WrapperHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0;
`

const SelectorsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const DataListWrapper = styled.div`
  display: block;
`

const SelectStyled = styled(Select)`
  ${({ theme }) => css`
    div[class*='-control'] {
      background-color: transparent;
      border-color: transparent;
      cursor: pointer;
      transition: all 0.3s;
      margin-left: 8px;
      min-width: 125px;
    }
    div[class*='-singleValue'] {
      ${({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${theme.colors.powerbi.ascent};
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        width: fit-content;
        height: 24px;
        padding: 2px 8px;
        box-sizing: border-box;
        border-radius: 4px;
      `}
    }
    div[class*='-singleValue'] > input {
      color: #fff !important;
    }
    div[class*='indicatorContainer'] {
      color: ${theme.colors.powerbi.ascent};
    }
    span[class*='indicatorSeparator'] {
      display: none;
    }
  `}
`

const SelectContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.56);
  &:first-of-type {
    margin-right: 30px;
  }
`
const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SelectNumber = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.powerbi.ascent};
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    width: fit-content;
    height: 24px;
    padding: 2px 8px;
    box-sizing: border-box;
    border-radius: 4px;
  `}
`

export default FilterHeader

import styled, { css } from 'styled-components'
import {
  Drawer,
  IconButton as IconButtonStyle,
  List,
  MenuItem,
  withStyles
} from '@mui/material'
import { Link, NavLink } from 'react-router-dom'
import { ReactComponent as ArrowsLeftStyle } from '../../../assets/imgs/powerbi/arrows-left.svg'
import { ReactComponent as ArrowsRightStyle } from '../../../assets/imgs/powerbi/arrows-right.svg'

// estrutura
export const Wrapper = styled.main`
  display: flex;
  background-color: '#FFF';
`
export const ContainerContent = styled.div`
  width: 100%;
  margin: 55px 40px 0 40px;

  @media (max-width: 930px) {
    margin: 55px 20px 0 20px;
  }
`

// menu lateral
export const DrawerStyle = styled(Drawer)`
  width: 327px;
`
export const DrawerContent = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`
export const Logo = styled.img`
  display: block;
  margin: 35px auto;
`
export const Button = styled(NavLink)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 1rem;
    color: ${theme.colors.gray};
    font-family: ${theme.font.family};
    font-weight: ${theme.font.semiBold};
    transition: all 0.3s;
    min-height: 52px;

    &.active,
    &:hover {
      background: ${theme.colors.powerbi.secondary};
      color: #000;
    }

    &.buttonClosed {
      justify-content: center;
    }

    &:hover > span > svg > path,
    &.active > span > svg > path,
    &:hover > svg > path,
    &.active > svg > path {
      fill-opacity: 1;
      fill: black;
    }
  `}
`
export const Icon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 15px;
  &:hover {
    fill: black;
    fill-opacity: 1;
    color: #000;
  }
  &.iconClosed {
    margin-right: 0;
  }

  & > img {
    display: block;
    max-width: 24px;
    max-height: 24px;
  }
`
export const TextButton = styled.span`
  display: inline-block;
`
export const Divisoria = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    align-items: flex-end;
    height: 41px;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.08);
    }
    &:hover span,
    &:hover svg path {
      color: ${theme.colors.powerbi.ascent};
      fill: ${theme.colors.powerbi.ascent};
      fill-opacity: 1;
    }
  `}
`
export const TextoDivisoria = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    padding-top: 15px;
    margin-left: 22px;
    font-size: 1rem;
    line-height: 25px;
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.gray};
    font-family: 'Open Sans', sans-serif;
  `}
`
export const ArrowsLeft = styled(ArrowsLeftStyle)`
  display: inline-block;
  margin-right: 20px;
`
export const ArrowsRight = styled(ArrowsRightStyle)`
  display: inline-block;
`
export const IconButton = styled(IconButtonStyle)`
  padding: 0 7px !important;
  margin: 0 auto !important;
  border-radius: 0;
  background-color: transparent;
  &:hover {
    background-color: transparent !important;
    box-shadow: none !important;
  }
`
export const LinkDropdown = styled(NavLink)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: transparent;
    font-weight: ${theme.font.semiBold};
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.body};
    line-height: 24px;
    margin-bottom: 20px;
    transition: all 0.3s;

    &.active,
    &:hover {
      color: ${theme.colors.powerbi.primary};
    }

    &:before {
      content: '';
      display: inline-block;
      background-color: transparent;
      margin: 0 16px 0 38px;
      width: 2px;
      height: 24px;
      transition: all 0.3s;
    }
    &.active:before,
    &:hover:before {
      background-color: ${theme.colors.powerbi.ascent};
    }
  `}
`
export const Dropdown = styled(List)`
  transition: all 0.3s;
  opacity: 0;
  z-index: -2;

  &.dropdownActive {
    opacity: 1;
    height: auto;
    z-index: 2;
  }
`
export const ArrowIconConfig = styled.span`
  position: absolute;
  display: inline-block;
  right: 22px;
  transition: all 0.3s ease-in;
`

// conteúdo e indicadores

export const WrapperCards = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const CardIndicator = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    flex: 1 1 30%;
    background-color: ${theme.colors.powerbi.lightgray};
    border: 1px solid lightgrey;
    border-left: none;
    border-radius: 4px;
    margin: 0 16px 16px 0;
    transition: all 0.3s;

    &:before {
      content: '';
      display: inline-block;
      background-color: ${theme.colors.powerbi.primary};
      width: 8px;
      height: 100%;
      border-radius: 4px 0px 0px 4px;
    }
    &:hover {
      border-color: #b8b8b8;
    }
  `}
`
export const ConteudoCardIndicator = styled.span`
  display: block;
  padding: 16px;
  box-sizing: border-box;
`

//Header das páginas com a configuração do botão de perfil
export const HeaderPages = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Titulo = styled.h2`
  font-weight: 600;
`
export const IconPerfil = styled.img`
  align-items: right;
  width: 56px;
  height: 56px;
`

export const IconPerfilInterno = styled.img`
  align-items: right;

  & > img {
    border: 2px solid salmon;
    display: block;
    max-width: 56px;
    max-height: 56px;
  }
`

export const PerfilIndicator = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    flex: 1 1 30%;
    background-color: ${theme.colors.powerbi.lightgray};
    border: 1px solid lightgrey;
    border-left: none;
    border-radius: 4px;
    margin: 0 16px 16px 0;
    transition: all 0.3s;

    &:before {
      content: '';
      display: inline-block;
      background-color: ${theme.colors.powerbi.primary};
      width: 8px;
      height: 100%;
      border-radius: 4px 0px 0px 4px;
    }
    &:hover {
      border-color: #b8b8b8;
    }
  `}
`
export const ConteudoCardPerfil = styled.span`
  display: block;
  padding: 16px;
  box-sizing: border-box;
`

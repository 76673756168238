import React from 'react'
import './FormCreate.css'
import Switch from 'components/pages/Home/Switch'

import {
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormGroup,
  FormControlLabel
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { makeStyles } from '@material-ui/styles'
import MaidaBtn from 'components/MaidaBtn'
import { useUserContext } from '../Data/UserContext'
import {
  ContainerInputs,
  Botao,
  ContainerGroupSelect,
  WorkspacesBox,
  Titulo,
  Situacao,
  ErrorSenha
} from 'components/pages/Home/Configurations/Users/CreateUser'
import { useWorkspaceContext } from '../Data/WorkspaceContext'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '50%',
    paddingBottom: '24px'
  }
})

type UserAPI = {
  name: string
  email: string
  googleEmail: string
  microsoftEmail: string
  password: string
  role: string
  workspaces: Array<string>
  active: boolean
}

const FormEdit = () => {
  const classes = useStyles()
  const { workspaces } = useWorkspaceContext()
  const { loading, currentUser, roles, atualizarUsuario, editUser } =
    useUserContext()

  //Campos
  const [nome, setNome] = React.useState(String(currentUser?.name))
  const [user, setUser] = React.useState(String(currentUser?.email))
  const [emailGoogle, setEmailGoogle] = React.useState(
    String(currentUser?.googleEmail)
  )
  const [emailMicrosoft, setEmailMicrosoft] = React.useState(
    String(currentUser?.microsoftEmail)
  )
  const [senha, setSenha] = React.useState(String(currentUser?.password))
  const [acesso, setAcesso] = React.useState(String(currentUser?.roles[0]))
  const [showPassword, setShowPassword] = React.useState(false)
  const [isToggled, setIsToggled] = React.useState(Boolean(currentUser?.active))
  const [workspacesUserSelect, setWorkspacesUserSelect] = React.useState(
    currentUser?.workspaces
  )

  //Erros
  const [errorNome, setErrorNome] = React.useState([false, ''])
  const [errorUser, setErrorUser] = React.useState([false, ''])
  const [errorEmailMicrosoft, setErrorEmailMicrosoft] = React.useState([
    false,
    ''
  ])
  const [errorEmailGoogle, setErrorEmailGoogle] = React.useState([false, ''])
  const [errorSenha, setErrorSenha] = React.useState([false, ''])

  const handleSubmit = (e) => {
    e.preventDefault()
    const UsuarioAPI: UserAPI = {
      name: nome,
      email: user,
      googleEmail: emailGoogle,
      microsoftEmail: emailMicrosoft,
      active: isToggled,
      password: senha,
      role: acesso,
      workspaces: workspacesUserSelect ? workspacesUserSelect : []
    }

    console.log(JSON.stringify(UsuarioAPI))

    if (UsuarioAPI) {
      const token = window.localStorage.getItem('token')
      editUser(token, UsuarioAPI, Number(currentUser?.id))
    }
  }

  const handleSetNome = (e) => {
    if (errorNome[0]) validateNome(e.target.value)
    setNome(e.target.value)
  }

  const handleChangeNome = (e) => {
    validateNome(e.target.value)
  }

  const validateNome = (valor) => {
    if (valor.length === 0) {
      setErrorNome([true, 'Preencha um nome.'])
      return false
    } else if (valor.length >= 50) {
      setErrorNome([true, 'O nome precisa ter menos de 50 caracteres.'])
      return false
    } else {
      setErrorNome([false, ''])
      return true
    }
  }

  const handleSetUser = (e) => {
    if (errorUser[0]) validateUser(e.target.value)
    setUser(e.target.value)
  }

  const handleChangeUser = (e) => {
    validateUser(e.target.value)
  }

  const validateUser = (valor) => {
    const regex = /\S+@\S+\.\S+/

    if (valor.length === 0) {
      setErrorUser([true, 'Preencha um e-mail.'])
      return false
    } else if (!regex.test(valor)) {
      setErrorUser([true, 'Preencha um e-mail válido. Ex: maida@maida.com'])
      return false
    } else {
      setErrorUser([false, ''])
      return true
    }
  }

  const handleSetEmailMicrosoft = (e) => {
    if (errorEmailMicrosoft[0]) validateEmailMicrosoft(e.target.value)
    setEmailMicrosoft(e.target.value)
  }

  const handleChangeEmailMicrosoft = (e) => {
    validateEmailMicrosoft(e.target.value)
  }

  const validateEmailMicrosoft = (valor) => {
    const regex = /\S+@\S+\.\S+/

    if (valor.length === 0) {
      setErrorEmailMicrosoft([true, 'Preencha um e-mail.'])
      return false
    } else if (!regex.test(valor)) {
      setErrorEmailMicrosoft([
        true,
        'Preencha um e-mail válido. Ex: maida@maida.com'
      ])
      return false
    } else {
      setErrorEmailMicrosoft([false, ''])
      return true
    }
  }

  const handleSetEmailGoogle = (e) => {
    if (errorEmailGoogle[0]) validateEmailGoogle(e.target.value)
    setEmailGoogle(e.target.value)
  }

  const handleChangeEmailGoogle = (e) => {
    validateEmailGoogle(e.target.value)
  }

  const validateEmailGoogle = (valor) => {
    const regex = /\S+@\S+\.\S+/

    if (valor.length === 0) {
      setErrorEmailGoogle([true, 'Preencha um e-mail.'])
      return false
    } else if (!regex.test(valor)) {
      setErrorEmailGoogle([
        true,
        'Preencha um e-mail válido. Ex: maida@maida.com'
      ])
      return false
    } else {
      setErrorEmailGoogle([false, ''])
      return true
    }
  }

  const validateSenha = (valor) => {
    if (valor.length === 0) {
      setErrorSenha([true, 'Preencha uma senha.'])
      return false
    } else if (valor.length >= 100) {
      setErrorSenha([true, 'A senha precisa ter menos de 50 caracteres.'])
      return false
    } else if (valor.length <= 3) {
      setErrorSenha([true, 'A senha precisa ter no mínimo 4 caracteres.'])
      return false
    } else {
      setErrorSenha([false, ''])
      return true
    }
  }

  const handleSetSenha = (e) => {
    if (errorSenha[0]) validateSenha(e.target.value)
    setSenha(e.target.value)
  }

  const handleChangeSenha = (e) => {
    validateSenha(e.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChangeCheckBox = ({ target }) => {
    if (target.checked) {
      if (workspacesUserSelect) {
        setWorkspacesUserSelect([...workspacesUserSelect, target.value])
      }
    } else {
      if (workspacesUserSelect) {
        setWorkspacesUserSelect(
          workspacesUserSelect.filter((itemValue) => itemValue !== target.value)
        )
      }
    }
  }

  React.useEffect(() => {
    return () => {
      atualizarUsuario(null)
    }
  }, [])

  return (
    <form noValidate>
      <ContainerInputs>
        <FormControl className={classes.input}>
          <TextField
            name="nome"
            required
            value={nome}
            id="nome"
            label="Nome Completo"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetNome}
            onBlur={handleChangeNome}
            helperText={errorNome[0] && errorNome[1]}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>
        <FormControl className={classes.input}>
          <TextField
            value={user}
            name="email"
            required
            id="email"
            label="E-mail"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetUser}
            onBlur={handleChangeUser}
            helperText={errorUser[0] && errorUser[1]}
            InputProps={{
              style: { fontSize: '14px', height: '56px' }
            }}
          />
        </FormControl>

        <FormControl className={classes.input}>
          <TextField
            name="microsoftEmail"
            required
            value={emailMicrosoft}
            id="microsoftEmail"
            label="Microsoft e-mail"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetEmailMicrosoft}
            onBlur={handleChangeEmailMicrosoft}
            helperText={errorEmailMicrosoft[0] && errorEmailMicrosoft[1]}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>
        <FormControl className={classes.input}>
          <TextField
            value={emailGoogle}
            name="googleEmail"
            required
            id="googleEmail"
            label="Google e-mail"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetEmailGoogle}
            onBlur={handleChangeEmailGoogle}
            helperText={errorEmailGoogle[0] && errorEmailGoogle[1]}
            InputProps={{
              style: { fontSize: '14px', height: '56px' }
            }}
          />
        </FormControl>

        {/* <FormControl className={classes.input} style={{ paddingRight: '24px' }}>
          <InputLabel htmlFor="senha" style={{ fontSize: '16px' }}>
            Senha
          </InputLabel>
          <OutlinedInput
            name="senha"
            required
            inputProps={{
              style: { fontSize: '16px' }
            }}
            id="senha"
            type={showPassword ? 'text' : 'password'}
            value={currentUser?.password}
            onChange={handleSetSenha}
            onBlur={handleChangeSenha}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={{ color: '#FF0073' }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={() => {
                    return null
                  }}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {errorSenha[0] && <ErrorSenha>{errorSenha[1]}</ErrorSenha>}
        </FormControl> */}

        <FormControl className={classes.input}>
          <InputLabel id="acesso" sx={{ fontSize: '16px' }}>
            Nível de Acesso
          </InputLabel>
          <Select
            variant="outlined"
            sx={{
              '& .MuiSelect-icon': { color: '#FF0073', fontSize: '26px' },
              '& .MuiSelect-select': {
                color: '#000000E0'
              },
              '& .MuiSelect-outlined': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px'
              }
            }}
            label="Nível de Acesso"
            name="acesso"
            id="acesso"
            value={acesso}
            onChange={(e) => setAcesso(e.target.value)}
          >
            {roles.map((option) => (
              <MenuItem
                style={{ fontSize: '16px' }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ContainerInputs>

      <Titulo>Quais relatórios ele poderá visualizar?</Titulo>
      <ContainerGroupSelect>
        <WorkspacesBox>
          {workspaces &&
            workspaces.map((item: any) => {
              return (
                <label className="label" key={item.id}>
                  <input
                    className="input"
                    type="checkbox"
                    checked={workspacesUserSelect?.includes(item.name)}
                    value={item.name}
                    onChange={handleChangeCheckBox}
                  />
                  <span className="custom-input">
                    <div className="containerSVG">{item.icon}</div>
                    <p>{item.name}</p>
                  </span>
                </label>
              )
            })}
        </WorkspacesBox>
      </ContainerGroupSelect>

      {/* Switch */}
      <Situacao>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                rounded={true}
                isToggled={isToggled}
                onToggle={() => {
                  setIsToggled(!isToggled)
                }}
              />
            }
            label={
              <p
                style={{
                  marginLeft: '18px',
                  padding: 0,
                  fontWeight: 'bold'
                }}
              >
                Usuário ativo
              </p>
            }
            sx={{
              marginLeft: '0'
            }}
          />
        </FormGroup>
      </Situacao>

      <Botao>
        {loading ? (
          <MaidaBtn text="Carregando ..." disabled={true} />
        ) : (
          <MaidaBtn
            text="Salvar"
            disabled={
              nome && user && senha && acesso && workspacesUserSelect
                ? false
                : true
            }
            onClick={handleSubmit}
            type="submit"
          ></MaidaBtn>
        )}
      </Botao>
    </form>
  )
}

export default FormEdit

const IconPassword = () => {
  return (
    <div style={{ position: 'absolute', left: '22px', top: '14px' }}>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 2C5.79086 2 4 3.79086 4 6C4 6.55228 3.55228 7 3 7C2.44772 7 2 6.55228 2 6C2 2.68629 4.68629 0 8 0C11.3137 0 14 2.68629 14 6C14 6.55228 13.5523 7 13 7C12.4477 7 12 6.55228 12 6C12 3.79086 10.2091 2 8 2Z"
          fill="#2B45D4"
        />
        <path
          d="M7.03144 14.7502C7.01092 14.8301 7 14.9138 7 15V16C7 16.5523 7.44772 17 8 17C8.55229 17 9 16.5523 9 16V15C9 14.9138 8.98908 14.8301 8.96856 14.7502C9.58366 14.4091 10 13.7532 10 13C10 11.8954 9.10457 11 8 11C6.89543 11 6 11.8954 6 13C6 13.7532 6.41634 14.4091 7.03144 14.7502Z"
          fill="#2B45D4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 8C0.447715 8 0 8.44771 0 9V19C0 19.5523 0.447715 20 1 20H15C15.5523 20 16 19.5523 16 19V9C16 8.44771 15.5523 8 15 8H1ZM2 18V10H14V18H2Z"
          fill="#2B45D4"
        />
      </svg>
    </div>
  )
}

export default IconPassword

import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import ItemList from './ItemList'
import { useIndicatorContext } from 'components/Data/IndicatorsContext'
import { useEmbeddedContext } from 'components/Data/EmbeddedContext'
import { useUserContext } from 'components/Data/UserContext'

const DataList = ({ itens }) => {
  const { id } = useParams()
  const { workspaces, listAllWorkspaces } = useWorkspaceContext()
  const { indicators, getIndicatorsWithFilter } = useIndicatorContext()
  const { services, listAll } = useEmbeddedContext()
  const { token } = useUserContext()

  useEffect(() => {
    listAllWorkspaces(token)
    getIndicatorsWithFilter(token, false, null)
    listAll(token, false)
  }, [token])

  return (
    <>
      <ul>
        {id === 'usuarios' &&
          itens.map((usuario, id) => {
            return (
              <ItemList
                usuario={usuario}
                key={id}
                idItem={usuario.id}
                title={usuario.name}
                active={usuario.active}
                description={usuario.email}
              />
            )
          })}
        {id === 'relatorios' &&
          workspaces.map((workspace, id) => {
            return (
              <ItemList
                workspace={workspace}
                key={id}
                idItem={workspace.id}
                title={workspace.name}
                active={workspace.active}
                description={workspace.createdAt}
                icon={workspace.iconUrl}
              />
            )
          })}
        {id === 'indicadores' &&
          indicators &&
          Array.isArray(indicators) &&
          indicators.map((item, id) => {
            return (
              <ItemList
                key={id}
                idItem={item.id}
                title={item.name}
                active={item.active}
                description={item.description}
                indicator={item}
              />
            )
          })}
        {id === 'embedded' &&
          services &&
          services.map((item, id) => {
            return (
              <ItemList
                key={id}
                idItem={item.id}
                title={item.name}
                active={item.active}
                description={item.description}
                embedded={item}
              />
            )
          })}
      </ul>
    </>
  )
}

export default DataList

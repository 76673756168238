import { API_URL } from './api'

export function GET_SERVICES(token, page) {
  return {
    url: `${API_URL}/embedded?page=${Number(page)}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  }
}

export function CREATE_SERVICE(token, body) {
  return {
    url: `${API_URL}/embedded`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

export function RESUME_SERVICE(token, body) {
  return {
    url: `${API_URL}/embedded/resumeService`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

export function PAUSE_SERVICE(token, body) {
  return {
    url: `${API_URL}/embedded/pauseService`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(body)
    }
  }
}

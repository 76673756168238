import * as React from 'react'

import styled from 'styled-components'
import {
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select
} from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import MaidaBtn from 'components/MaidaBtn'
import { useIndicatorContext } from 'components/Data/IndicatorsContext'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import NewSnackbar from 'components/NewSnackbar'
const useStyles = makeStyles({
  input: {
    width: '50%',
    paddingBottom: '24px'
  },
  select: {
    outline: '1px solid orange'
  },
  texto: {
    fontSize: '16px'
  }
})

type IndicatorAPI = {
  name: string
  workspace: any
  description: string
  workspaceCode: string
  reportCode: string
  active: boolean
}

const EditIndicator = () => {
  const classes = useStyles()
  const { activeWorkspaces, listActivesWorkspaces } = useWorkspaceContext()
  const {
    editIndicator,
    indicatorData,
    setIndicator,
    open,
    setOpen,
    severity,
    feedbackMessage
  } = useIndicatorContext()

  const [nomeIndicador, setNomeIndicador] = React.useState(
    String(indicatorData?.name)
  )
  const [descricao, setDescricao] = React.useState(
    String(indicatorData?.description)
  )
  const [workspace, setWorkspace] = React.useState(
    String(indicatorData?.workspaceCode)
  )
  const [report, setReport] = React.useState(indicatorData?.reportCode)
  const [relatorio, setRelatorio] = React.useState(
    String(indicatorData?.workspace)
  )
  const [errorName, setErrorName] = React.useState([false, ''])
  const [errorDesc, setErrorDesc] = React.useState([false, ''])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (
      validateName(nomeIndicador) &&
      validateDesc(descricao) &&
      validateReport(relatorio) &&
      validateWorkspaceCode(workspace) &&
      validateReportCode(report)
    ) {
      const indicadorAPI: IndicatorAPI = {
        name: nomeIndicador,
        description: descricao,
        active: true,
        workspace: relatorio,
        workspaceCode: workspace,
        reportCode: report
      }

      if (indicadorAPI) {
        const token = window.localStorage.getItem('token')
        editIndicator(token, indicatorData?.id, indicadorAPI)
      }
    }
    // navigate('/home/configuracoes/indicadores')
  }

  const validateName = (valor) => {
    if (valor.length === 0) {
      setErrorName([true, 'Preencha o nome.'])
      return false
    } else if (valor.length > 50) {
      setErrorName([true, 'O nome precisa ter menos de 50 caracteres.'])
      return false
    } else {
      setErrorName([false, ''])
      return true
    }
  }

  const validateDesc = (valor) => {
    if (valor.length === 0) {
      setErrorDesc([true, 'Preencha a descrição.'])
      return false
    } else if (valor.length >= 150) {
      setErrorDesc([true, 'A descrição precisa ter menos de 150 caracteres.'])
      return false
    } else {
      setErrorDesc([false, ''])
      return true
    }
  }

  const validateReport = (valor) => {
    if (!valor) {
      setErrorDesc([true, 'Informe o relatório.'])
      return false
    } else {
      setErrorDesc([false, ''])
      return true
    }
  }

  const validateWorkspaceCode = (valor) => {
    if (!valor) {
      setErrorDesc([true, 'Informe o Workspace.'])
      return false
    } else {
      setErrorDesc([false, ''])
      return true
    }
  }

  const validateReportCode = (valor) => {
    if (!valor) {
      setErrorDesc([true, 'Informe o Report.'])
      return false
    } else {
      setErrorDesc([false, ''])
      return true
    }
  }

  const handleChangeName = (e) => {
    validateName(e.target.value)
  }

  const handleChangeDesc = (e) => {
    validateDesc(e.target.value)
  }

  React.useEffect(() => {
    return () => {
      const token = window.localStorage.getItem('token')
      setIndicator(null)
      listActivesWorkspaces(token)
    }
  }, [])

  return (
    <ContainerBox>
      <form onSubmit={handleSubmit}>
        <FormControl style={{ display: 'flex' }}>
          <InputLabel id="relatorio ">Selecionar Relatório</InputLabel>
          <Select
            style={{ marginBottom: '24px' }}
            variant="outlined"
            fullWidth
            sx={{
              '& .MuiSelect-icon': { color: '#FF0073', fontSize: '26px' },
              '& .MuiSelect-select': {
                color: '#000000E0'
              },
              '& .MuiSelect-outlined': {
                fontSize: '16px'
              }
            }}
            label="Selecionar Relatório"
            id="relatorio"
            value={relatorio}
            onChange={(e) => setRelatorio(e.target.value)}
          >
            {activeWorkspaces.map((item) => (
              <MenuItem
                style={{
                  fontSize: '16px',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-start',
                  margin: '5px 10px'
                }}
                key={item.name}
                value={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ContainerInputs>
          <FormControl
            className={classes.input}
            style={{ paddingRight: '24px' }}
          >
            <TextField
              label="Nome do Indicador"
              variant="outlined"
              inputProps={{ className: classes.texto }}
              value={nomeIndicador}
              onChange={(e) => {
                if (errorName[0]) validateName(e.target.value)
                setNomeIndicador(e.target.value)
              }}
              onBlur={handleChangeName}
              helperText={errorName[0] && errorName[1]}
            />
          </FormControl>

          <FormControl className={classes.input}>
            <TextField
              id="desc"
              label="Descrição"
              value={descricao}
              maxRows={3}
              inputProps={{ className: classes.texto }}
              onChange={(e) => {
                if (errorDesc[0]) validateDesc(e.target.value)
                setDescricao(e.target.value)
              }}
              onBlur={handleChangeDesc}
              helperText={errorDesc[0] && errorDesc[1]}
            />
          </FormControl>

          <FormControl
            className={classes.input}
            style={{ paddingRight: '24px' }}
          >
            <TextField
              label="Workspace"
              variant="outlined"
              value={workspace}
              inputProps={{ className: classes.texto }}
              onChange={(e) => setWorkspace(e.target.value)}
              // helperText={errorText[0] && errorText[1]}
            />
          </FormControl>

          <FormControl className={classes.input}>
            <TextField
              label="Report"
              variant="outlined"
              value={report}
              inputProps={{ className: classes.texto }}
              onChange={(e) => setReport(e.target.value)}
              // helperText={errorText[0] && errorText[1]}
            />
          </FormControl>
        </ContainerInputs>

        <Botao>
          <MaidaBtn
            type="submit"
            text="Salvar"
            disabled={
              relatorio && nomeIndicador && descricao && report && workspace
                ? false
                : true
            }
          ></MaidaBtn>
        </Botao>
      </form>

      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </ContainerBox>
  )
}

const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
`
const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Botao = styled.div`
  float: right;
  margin-top: 32px;
`

export default EditIndicator

const IconEmail = () => {
  return (
    <div style={{ position: 'absolute', left: '19px', top: '14px' }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 11C4.55228 11 5 11.4477 5 12V17C5 17.5523 5.44772 18 6 18H18C18.5523 18 19 17.5523 19 17V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V17C21 18.6569 19.6569 20 18 20H6C4.34315 20 3 18.6569 3 17V12C3 11.4477 3.44772 11 4 11Z"
          fill="#2B45D4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6.9807C3 5.3345 4.3345 4 5.9807 4H18.0193C19.6655 4 21 5.3345 21 6.9807C21 7.94332 20.5351 8.84668 19.7518 9.40619L14.1028 13.4412C13.594 13.8046 12.9843 14 12.3591 14H11.6409C11.0157 14 10.406 13.8046 9.89722 13.4412L4.2482 9.40619C3.46489 8.84668 3 7.94332 3 6.9807ZM5.9807 6C5.43907 6 5 6.43907 5 6.9807C5 7.29741 5.15296 7.59464 5.41068 7.77872L11.0597 11.8137C11.2293 11.9349 11.4325 12 11.6409 12H12.3591C12.5675 12 12.7707 11.9349 12.9403 11.8137L18.5893 7.77872C18.847 7.59464 19 7.29742 19 6.9807C19 6.43907 18.5609 6 18.0193 6H5.9807Z"
          fill="#2B45D4"
        />
      </svg>
    </div>
  )
}

export default IconEmail

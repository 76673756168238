import React, { ReactNode, useContext } from 'react'
import {
  CHANGE_FORGETED_PASSWORD,
  FORGET_PASSWORD,
  TOKEN_GOOGLE_POST,
  TOKEN_MICROSOFT_POST,
  TOKEN_POST
} from './API/api'
import {
  USER_GET,
  CREATE_USER_POST,
  GET_USERS,
  DELETE_USER,
  EDIT_USER_PUT,
  EDIT_PERFIL_USER_PUT,
  USER_GET_BY_USER,
  GET_USERS_NO_ADMIN
} from '../Data/API/User'
import { useNavigate } from 'react-router-dom'
import { Providers, ProviderState } from '@microsoft/mgt-element'

import { UserProps } from '../Data/Types/UserType'

interface ContextValue {
  userData: UserProps | null
  login: boolean
  getUser: (token: string) => void
  getUserByUser: (token: string | null, user: string) => void
  getUsers: (token: string, incrementPage: boolean) => void
  getUsersWithFilter: (
    token: string,
    incrementPage: boolean,
    filter: string | null
  ) => void
  getUsersWithFilterNoAdmin: (
    token: string,
    incrementPage: boolean,
    filter: string | null
  ) => void
  users: Array<UserProps> | []
  usersNoAdmin: Array<UserProps> | []
  userLogin: (email: string, password: string) => void
  userLoginMicrosoft: (email: string, token: string) => void
  userLoginGoogle: (email: string) => void
  forgetPassword: (email: string) => void
  userLogout: () => void
  createUser: (token: string | null, Pessoa: UserAPI) => void
  createUserByCSV: (token: string | null, Pessoa: UserAPI) => void
  editUser: (token: string | null, Pessoa: UserAPI, id: number) => void
  changeAccess: (token: string | null, Pessoa: UserAPI, id: number) => void
  changePassword: (token: string | null, Pessoa: UserAPI, id: number) => void
  changeForgetedPassword: (pessoa: any) => void
  editPerfilUser: (token: string | null, Pessoa: UserAPI, id: number) => void
  deleteUser: (token: string | null, id: number) => void
  loading: boolean
  error: boolean | string | null
  errorMicrosoft: boolean | string | null
  errorGoogle: boolean | string | null
  token: string | null
  open: boolean
  setOpen: any
  severity: string
  setSeverity: any
  feedbackMessage: string
  setFeedbackMessage: any
  currentUser: UserProps | undefined | null
  atualizarUsuario: (usuario: UserProps | undefined | null) => void
  roles: Array<rolesProps>
  workspacesUserSelect: string[]
  setWorkspacesUserSelect: any
}

type rolesProps = {
  value: string
  label: string
}
type UserAPI = {
  name: string
  email: string
  active: boolean
  password: string
  role: string
  workspaces: Array<string>
}

interface Props {
  children: ReactNode
}

export const UserContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
)

export const UserContextProvider = ({ children }: Props) => {
  const [userData, setUserData] = React.useState<UserProps | null>(null)

  const roles = [
    {
      value: 'USER',
      label: 'Visualização '
    },
    {
      value: 'ADMIN',
      label: 'Administrador'
    }
  ]

  const [login, setLogin] = React.useState(false)
  const [error, setError] = React.useState<boolean | string | null>(null)
  const [errorMicrosoft, setErrorMicrosoft] =
    React.useState<boolean | string | null>(null)
  const [errorGoogle, setErrorGoogle] =
    React.useState<boolean | string | null>(null)
  const [loading, setLoading] = React.useState(false)
  const token = window.localStorage.getItem('token') || null
  const [workspacesUserSelect, setWorkspacesUserSelect] = React.useState<
    string[]
  >([])

  const [totalPagesUsers, setTotalPageUsers] = React.useState<number>(1)
  const [pageUsers, setPageUsers] = React.useState<number>(0)

  // Feedback
  const [open, setOpen] = React.useState<boolean>(false)
  const [severity, setSeverity] = React.useState<string>('')
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('')

  // Edição
  const [currentUser, setCurrentUser] =
    React.useState<UserProps | undefined | null>(null)

  const atualizarUsuario = (usuario) => {
    setCurrentUser(usuario)
  }
  //==============

  const navigate = useNavigate()

  const [users, setUsers] = React.useState<UserProps[]>([])
  const [usersNoAdmin, setUsersNoAdmin] = React.useState<UserProps[]>([])

  const userLogout = React.useCallback(
    async function () {
      setUserData(null)
      setError(false)
      setLoading(false)
      setLogin(false)
      window.localStorage.removeItem('token')
      navigate('/')

      //logout microsoft
      const provider = Providers.globalProvider
      if (provider && provider.state === ProviderState.SignedIn) {
        if (provider.logout) provider.logout()
      }
    },
    [navigate]
  )

  React.useEffect(() => {
    autoLogin()
  }, [login])

  function isTokenExpired(token) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    return Math.floor(new Date().getTime() / 1000) >= expiry
  }

  async function autoLogin() {
    const token = window.localStorage.getItem('token')
    if (token && !isTokenExpired(token)) {
      setLogin(true)
      getUser(token)
    } else {
      userLogout()
    }
  }

  // async function autoLogin() {
  //   const token = window.localStorage.getItem('token')
  //   console.log(token)
  //   if (token && currentUser) {
  //     try {
  //       setError(false)
  //       setLoading(true)
  //       await getUser(token)
  //       navigate('/home/0')
  //     } catch (err) {
  //       window.localStorage.removeItem('token')
  //       userLogout()
  //     } finally {
  //       setLoading(false)
  //     }
  //   }
  // }

  async function getUser(token) {
    const { url, options } = USER_GET(token)

    try {
      const response = await fetch(url, options)
      if (!response.ok) return
      const json = await response.json()
      setLogin(true)
      setUserData(json)
    } catch (error) {
      setLogin(false)
      userLogout()
    }
  }

  async function getUsers(token: string, increment: boolean) {
    try {
      setError(false)
      setLoading(true)
      const t = token ? token : window.localStorage.getItem('token')
      if (increment) {
        setPageUsers((pageUsers ? pageUsers : 0) + 1)
      } else if (!increment) {
        setPageUsers(0)
      }

      //Não deve atualizar caso o numero de páginas seja maior que o numero de total retornado pelo server
      if (totalPagesUsers < pageUsers) {
        setPageUsers(totalPagesUsers)
      }

      const { url, options } = GET_USERS(t, pageUsers)
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const json = await response.json()
      const { content, totalPages } = json
      setTotalPageUsers(totalPages)
      setUsers(pageUsers == 0 ? content : users.concat(content))
      console.log(totalPages)
      console.log(pageUsers)
    } catch (error) {
      setError(String(error))
    } finally {
      setLoading(false)
    }
  }

  async function getUsersWithFilter(
    token: string,
    increment: boolean,
    filter: string | null
  ) {
    try {
      setError(false)
      setLoading(true)
      const t = token ? token : window.localStorage.getItem('token')
      if (increment) {
        setPageUsers((pageUsers ? pageUsers : 0) + 1)
      } else if (!increment) {
        setPageUsers(0)
      }

      //Não deve atualizar caso o numero de páginas seja maior que o numero de total retornado pelo server
      if (totalPagesUsers < pageUsers) {
        setPageUsers(totalPagesUsers)
      }

      const { url, options } = GET_USERS(t, pageUsers, filter)
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const json = await response.json()
      const { content, totalPages } = json
      setTotalPageUsers(totalPages)
      setUsers(pageUsers == 0 ? content : users.concat(content))
    } catch (error) {
      setError(String(error))
      // console.log('ERRO NO GET USERS = ', error)
      // setLogin(false)
    } finally {
      setLoading(false)
    }
  }

  async function getUsersWithFilterNoAdmin(
    token: string,
    increment: boolean,
    filter: string | null
  ) {
    try {
      setError(false)
      setLoading(true)
      const t = token ? token : window.localStorage.getItem('token')
      if (increment) {
        setPageUsers((pageUsers ? pageUsers : 0) + 1)
      } else if (!increment) {
        setPageUsers(0)
      }

      //Não deve atualizar caso o numero de páginas seja maior que o numero de total retornado pelo server
      if (totalPagesUsers < pageUsers) {
        setPageUsers(totalPagesUsers)
      }

      const { url, options } = GET_USERS_NO_ADMIN(t, pageUsers, filter)
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const json = await response.json()
      const { content, totalPages } = json
      setTotalPageUsers(totalPages)
      setUsersNoAdmin(pageUsers == 0 ? content : usersNoAdmin.concat(content))
    } catch (error) {
      setError(String(error))
      // console.log('ERRO NO GET USERS = ', error)
      // setLogin(false)
    } finally {
      setLoading(false)
    }
  }

  async function userLogin(email, password) {
    try {
      setError(false)
      setLoading(true)
      const { url, options } = TOKEN_POST({
        email: email,
        password: password
      })
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const { token } = await response.json()
      window.localStorage.setItem('token', token)
      await getUser(token)
      navigate('/home/0')
    } catch (error) {
      setError(String(error))
      setLogin(false)
    } finally {
      setLoading(false)
    }
  }

  async function userLoginGoogle(email) {
    try {
      setError(false)
      setLoading(true)
      const { url, options } = TOKEN_GOOGLE_POST({
        email: email
      })
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const { token } = await response.json()
      window.localStorage.setItem('token', token)
      await getUser(token)

      navigate('/home/0')
    } catch (error) {
      setErrorGoogle(String(error))
      setLogin(false)
    } finally {
      setLoading(false)
    }
  }

  async function userLoginMicrosoft(email, tokenMicrosoft) {
    try {
      setError(false)
      setLoading(true)
      const { url, options } = TOKEN_MICROSOFT_POST({
        email: email,
        tokenMicrosoft: tokenMicrosoft
      })
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const { token } = await response.json()
      window.localStorage.setItem('token', token)
      await getUser(token)

      navigate('/home/0')
    } catch (error) {
      setErrorMicrosoft(String(error))
      setLogin(false)
    } finally {
      setLoading(false)
    }
  }

  async function forgetPassword(email) {
    try {
      setError(false)
      setLoading(true)
      const { url, options } = FORGET_PASSWORD(email)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const body = await response.json()
      return body.message
    } catch (error) {
      setError(String(error))
      setLogin(false)
    } finally {
      setLoading(false)
    }
  }

  async function changeForgetedPassword(user: any) {
    try {
      setError(false)
      setLoading(true)
      const { url, options } = CHANGE_FORGETED_PASSWORD(user)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }

      const { token } = await response.json()
      window.localStorage.setItem('token', token)
      await getUser(token)

      navigate('/home/0')
    } catch (error) {
      setError(String(error))
      setLogin(false)
    } finally {
      setLoading(false)
    }
  }

  async function getUserByUser(token: string | null, user) {
    try {
      setError(false)
      setLoading(true)
      const { url, options } = USER_GET_BY_USER(token, {
        user
      })
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.message)
      }
      const { content } = await response.json()
      setUserData(content)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function createUser(token: string | null, Pessoa: UserAPI) {
    try {
      setLoading(true)

      const { url, options } = CREATE_USER_POST(token, Pessoa)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()

        throw new Error(body.erro)
      }
      setError(null)
      setSeverity('success')
      setFeedbackMessage('Usuário criado com sucesso!')
      setOpen(true)
      setTimeout(() => {
        navigate('/home/configuracoes/usuarios')
      }, 2000)
    } catch (error) {
      setError(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function createUserByCSV(token: string | null, Pessoa: UserAPI) {
    try {
      const { url, options } = CREATE_USER_POST(token, Pessoa)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()
        return body.erro
      }
      return 'Usuário criado com sucesso!'
    } catch (error) {
      return error.message
    }
  }

  async function editUser(token: string | null, Pessoa: UserAPI, id: number) {
    try {
      setLoading(true)

      const { url, options } = EDIT_USER_PUT(token, Pessoa, id)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()

        throw new Error(body.erro)
      }
      setError(null)
      setSeverity('success')
      setFeedbackMessage('Usuário modificado com sucesso!')
      setOpen(true)
      await getUser(token)
      setTimeout(() => {
        navigate('/home/configuracoes/usuarios')
      }, 2000)
    } catch (error) {
      console.log(error)
      setError(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function changeAccess(
    token: string | null,
    Pessoa: UserAPI,
    id: number
  ) {
    try {
      setLoading(true)

      const { url, options } = EDIT_USER_PUT(token, Pessoa, id)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()

        throw new Error(body.erro)
      }
      setError(null)
      setSeverity('success')
      setFeedbackMessage('Usuário modificado com sucesso!')
      setOpen(true)
    } catch (error) {
      console.log(error)
      setError(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function changePassword(
    token: string | null,
    Pessoa: UserAPI,
    id: number
  ) {
    try {
      setLoading(true)

      const { url, options } = EDIT_USER_PUT(token, Pessoa, id)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()

        throw new Error(body.erro)
      }
      setError(null)
      setSeverity('success')
      setFeedbackMessage('Senha modificado com sucesso!')
      setOpen(true)
      await getUser(token)
      setTimeout(() => {
        navigate('/home')
      }, 2000)
    } catch (error) {
      console.log(error)
      setError(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function editPerfilUser(
    token: string | null,
    Pessoa: UserAPI,
    id: number
  ) {
    try {
      setLoading(true)

      const { url, options } = EDIT_PERFIL_USER_PUT(token, Pessoa, id)

      console.log(JSON.stringify(Pessoa))
      console.log(options)
      const response = await fetch(url, options)

      if (!response.ok) {
        const body = await response.json()

        throw new Error(body.erro)
      }
      setError(null)
      setSeverity('success')
      setFeedbackMessage('Perfil modificado com sucesso!')
      setOpen(true)
      await getUser(token)
      // setTimeout(() => {
      //   navigate('/home/configuracoes/usuarios')
      // }, 2000)
    } catch (error) {
      console.log(error)
      setError(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function deleteUser(token: string | null, id: number) {
    try {
      const { url, options } = DELETE_USER(token, id)
      const response = await fetch(url, options)
      const json = await response.json()

      if (response.ok) {
        setSeverity('success')
        setFeedbackMessage(json.message)
        setOpen(true)
      } else {
        throw new Error(`${response.status}`)
      }
    } catch (err) {
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    }
  }

  const value = {
    userData,
    login,
    getUser,
    getUsers,
    getUsersWithFilter,
    getUsersWithFilterNoAdmin,
    getUserByUser,
    userLogin,
    userLoginMicrosoft,
    userLoginGoogle,
    forgetPassword,
    userLogout,
    createUser,
    createUserByCSV,
    changePassword,
    changeForgetedPassword,
    editUser,
    changeAccess,
    editPerfilUser,
    error,
    errorMicrosoft,
    errorGoogle,
    loading,
    token,
    users,
    usersNoAdmin,
    open,
    setOpen,
    severity,
    setSeverity,
    feedbackMessage,
    setFeedbackMessage,
    currentUser,
    atualizarUsuario,
    roles,
    deleteUser,
    workspacesUserSelect,
    setWorkspacesUserSelect
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export function useUserContext() {
  const context = useContext(UserContext)

  if (typeof context === 'undefined') {
    throw new Error('useUserContext must be used within an UserContext')
  }

  return context
}

import {
  FormControl,
  TextField,
  InputLabel,
  FormGroup,
  FormControlLabel
} from '@mui/material'
import Switch from 'components/pages/Home/Switch'
import { makeStyles } from '@material-ui/styles'
import { useAppContext } from 'components/Data/AppContext'
import { useUserContext } from 'components/Data/UserContext'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import * as S from './styles'
import { Botao, Situacao } from './Configurations/Users/CreateUser'
import MaidaBtn from 'components/MaidaBtn'
import NewSnackbar from 'components/NewSnackbar'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '100%',
    paddingBottom: '24px'
  }
})

type UserAPI = {
  name: string
  email: string
  password: string
  role: string
  workspaces: Array<string>
  active: boolean
  microsoftEmail: string
  googleEmail: string
}

const Perfil = () => {
  const classes = useStyles()
  const {
    open,
    setOpen,
    severity,
    feedbackMessage,
    loading,
    userData,
    editPerfilUser,
    atualizarUsuario
  } = useUserContext()
  const [associarMicrosoft, setAssociarMicrosoft] = useState(
    userData?.microsoftEmail ? true : false
  )
  const [emailMicrosoft, setEmailMicrosoft] = useState(userData?.microsoftEmail)
  const [associarGoogle, setAssociarGoogle] = useState(
    userData?.googleEmail ? true : false
  )
  const [emailGoogle, setEmailGoogle] = useState(userData?.googleEmail)
  const [errorEmailMicrosoft, setErrorEmailMicrosoft] = React.useState([
    false,
    ''
  ])
  const [errorEmailGoogle, setErrorEmailGoogle] = React.useState([false, ''])

  const handleSetEmailMicrosoft = (e) => {
    if (errorEmailMicrosoft[0]) validateEmailMicrosoft(e.target.value)
    setEmailMicrosoft(e.target.value)
  }

  const handleChangeEmailMicrosoft = (e) => {
    validateEmailMicrosoft(e.target.value)
  }

  const validateEmailMicrosoft = (valor) => {
    const regex = /\S+@\S+\.\S+/

    if (valor.length === 0) {
      setErrorEmailMicrosoft([true, 'Preencha um e-mail.'])
      return false
    } else if (!regex.test(valor)) {
      setErrorEmailMicrosoft([
        true,
        'Preencha um e-mail válido. Ex: maida@dominiomicrosoft.com'
      ])
      return false
    } else {
      setErrorEmailMicrosoft([false, ''])
      return true
    }
  }

  const handleSetEmailGoogle = (e) => {
    if (errorEmailGoogle[0]) validateEmailGoogle(e.target.value)
    setEmailGoogle(e.target.value)
  }

  const handleChangeEmailGoogle = (e) => {
    validateEmailGoogle(e.target.value)
  }

  const validateEmailGoogle = (valor) => {
    const regex = /\S+@\S+\.\S+/

    if (valor.length === 0) {
      setErrorEmailGoogle([true, 'Preencha um e-mail.'])
      return false
    } else if (!regex.test(valor)) {
      setErrorEmailGoogle([
        true,
        'Preencha um e-mail válido. Ex: maida@dominiogoogle.com'
      ])
      return false
    } else {
      setErrorEmailGoogle([false, ''])
      return true
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const UsuarioAPI: UserAPI = {
      name: userData?.name ? userData?.name : '',
      email: userData?.email ? userData?.email : '',
      active: userData?.active ? userData?.active : true,
      password: userData?.password ? userData?.password : '',
      role: userData?.roles ? userData?.roles[0] : '',
      workspaces: [],
      microsoftEmail: associarMicrosoft && emailMicrosoft ? emailMicrosoft : '',
      googleEmail: associarGoogle && emailGoogle ? emailGoogle : ''
    }

    if (UsuarioAPI) {
      const token = window.localStorage.getItem('token')
      editPerfilUser(token, UsuarioAPI, Number(userData?.id))
    }
  }

  React.useEffect(() => {
    return () => {
      atualizarUsuario(null)
    }
  }, [])

  return (
    <section>
      <span className="pink">perfil</span>
      <form noValidate>
        <FormControl className={classes.input}>
          <TextField
            name="user"
            id="user"
            label="Usuário"
            variant="outlined"
            disabled={true}
            value={userData?.name}
            InputLabelProps={{ className: classes.texto }}
            InputProps={{
              style: {
                fontSize: '14px',
                height: '56px',
                marginRight: '24px'
              }
            }}
          />
        </FormControl>
        <Situacao>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  rounded={true}
                  isToggled={associarMicrosoft}
                  onToggle={() => {
                    setAssociarMicrosoft(!associarMicrosoft)
                  }}
                />
              }
              label={
                <p
                  style={{
                    marginLeft: '18px',
                    padding: 0,
                    fontWeight: 'bold'
                  }}
                >
                  Vincular usuário a conta Microsoft?
                </p>
              }
              sx={{
                marginLeft: '0',
                marginBottom: 2
              }}
            />
            {associarMicrosoft && (
              <FormControl className={classes.input}>
                <TextField
                  name="emailMicrosoft"
                  id="emailMicrosoft"
                  label="E-mail Microsoft"
                  variant="outlined"
                  InputLabelProps={{ className: classes.texto }}
                  onChange={handleSetEmailMicrosoft}
                  onBlur={handleChangeEmailMicrosoft}
                  value={emailMicrosoft}
                  helperText={errorEmailMicrosoft[0] && errorEmailMicrosoft[1]}
                  InputProps={{
                    style: {
                      fontSize: '14px',
                      height: '56px',
                      marginRight: '24px'
                    }
                  }}
                />
              </FormControl>
            )}
          </FormGroup>
        </Situacao>
        <Situacao>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  rounded={true}
                  isToggled={associarGoogle}
                  onToggle={() => {
                    setAssociarGoogle(!associarGoogle)
                  }}
                />
              }
              label={
                <p
                  style={{
                    marginLeft: '18px',
                    padding: 0,
                    fontWeight: 'bold'
                  }}
                >
                  Vincular usuário a conta Google?
                </p>
              }
              sx={{
                marginLeft: '0',
                marginBottom: 2
              }}
            />
            {associarGoogle && (
              <FormControl className={classes.input}>
                <TextField
                  name="emailGoogle"
                  id="emailGoogle"
                  label="E-mail Google"
                  variant="outlined"
                  InputLabelProps={{ className: classes.texto }}
                  onChange={handleSetEmailGoogle}
                  onBlur={handleChangeEmailGoogle}
                  value={emailGoogle}
                  helperText={errorEmailGoogle[0] && errorEmailGoogle[1]}
                  InputProps={{
                    style: {
                      fontSize: '14px',
                      height: '56px',
                      marginRight: '24px'
                    }
                  }}
                />
              </FormControl>
            )}
          </FormGroup>
        </Situacao>

        <Botao>
          {loading ? (
            <MaidaBtn text="Carregando ..." disabled={true} />
          ) : (
            <MaidaBtn
              text="Salvar"
              disabled={
                ((associarMicrosoft && !errorEmailMicrosoft[0]) ||
                  !associarMicrosoft) &&
                ((associarGoogle && !errorEmailGoogle[0]) || !associarGoogle)
                  ? false
                  : true
              }
              onClick={handleSubmit}
              type="submit"
            ></MaidaBtn>
          )}
        </Botao>
      </form>
      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </section>
  )
}

export default Perfil

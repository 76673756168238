import { makeStyles } from '@material-ui/styles'
import styled, { css } from 'styled-components'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'
import { useIndicatorContext } from 'components/Data/IndicatorsContext'
import React, { useState, useEffect } from 'react'
import { ContainerInputs, Botao } from './Configurations/Users/CreateUser'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import MaidaBtn from 'components/MaidaBtn'
import { useAuditoriaContext } from 'components/Data/AuditoriaContext'
import { useUserContext } from 'components/Data/UserContext'
import { UserProps } from 'components/Data/Types/UserType'
import { CSVLink } from 'react-csv'
import NewSnackbar from 'components/NewSnackbar'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '50%',
    paddingBottom: '24px'
  }
})

const Auditoria = () => {
  const yestarday = new Date()
  yestarday.setDate(yestarday.getDate() - 1)

  const classes = useStyles()
  const { getIndicatorsWithFilter, indicators } = useIndicatorContext()
  const { getUserByUser, userData, token } = useUserContext()
  const {
    loading,
    logs,
    listAll,
    errorMessage,
    open,
    setOpen,
    feedbackMessage,
    severity
  } = useAuditoriaContext()

  const [user, setUser] = useState('')
  const [indicator, setIndicator] = useState('')
  const [dateStart, setDateStart] = useState<any | null>(yestarday)
  const [dateEnd, setDateEnd] = useState<any | null>(new Date())
  const [errorUser, setErrorUser] = React.useState([false, ''])
  const [dataCSV, setDataCSV] = useState()

  useEffect(() => {
    return () => {
      if (indicator.length <= 0) getIndicatorsWithFilter(token, false, null)
    }
  }, [])

  const handleSetUser = (e) => {
    if (errorUser[0]) validateUser(e.target.value)
    setUser(e.target.value)
  }

  const handleChangeUser = (e) => {
    validateUser(e.target.value)
  }

  const validateUser = (valor) => {
    if (valor.length >= 50) {
      setErrorUser([true, 'O nome precisa ter menos de 50 caracteres.'])
      return false
    } else {
      setErrorUser([false, ''])
      return true
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let user
    let i

    for (const index of indicators) {
      if (index.name === indicator) i = index
    }

    if (user) {
      await getUserByUser(token, user)
      user = userData
    }

    await listAll(
      token,
      false,
      dateStart,
      dateEnd,
      user ? user.id : 0,
      i ? i.id : 0
    )
  }

  return (
    <form noValidate>
      <ContainerInputs>
        <FormControl className={classes.input}>
          <TextField
            name="email"
            id="email"
            label="Usuário"
            variant="outlined"
            InputLabelProps={{ className: classes.texto }}
            onChange={handleSetUser}
            onBlur={handleChangeUser}
            value={user}
            helperText={errorUser[0] && errorUser[1]}
            InputProps={{
              style: { fontSize: '14px', height: '56px' }
            }}
          />
        </FormControl>
        <FormControl className={classes.input}>
          <InputLabel id="acesso" sx={{ fontSize: '16px' }}>
            Indicador
          </InputLabel>
          <Select
            variant="outlined"
            sx={{
              '& .MuiSelect-icon': { color: '#FF0073', fontSize: '26px' },
              '& .MuiSelect-select': {
                color: '#000000E0'
              },
              '& .MuiSelect-outlined': {
                fontSize: '16px'
              }
            }}
            label="Indicador"
            name="acesso"
            id="acesso"
            value={indicator}
            onChange={(e) => setIndicator(e.target.value)}
          >
            {indicators.map((option) => (
              <MenuItem
                style={{
                  fontSize: '16px',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-start',
                  margin: '5px 10px'
                }}
                key={option.id}
                value={option}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ContainerInputs>
      <ContainerInputs>
        <FormControl className={classes.input}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data Inicial"
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl className={classes.input}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              key={'br'}
              label="Data Final"
              mask="dd/MM/yyyy"
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue)
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </ContainerInputs>
      <Botao>
        {loading ? (
          <MaidaBtn text="Carregando ..." disabled={true} />
        ) : (
          <MaidaBtn
            text="Consultar"
            disabled={
              dateStart && dateEnd && dateStart < dateEnd ? false : true
            }
            onClick={handleSubmit}
            type="submit"
          ></MaidaBtn>
        )}
        {logs && logs.length > 0 && (
          <Botao>
            <Button disabled={logs && logs.length > 0 ? false : true}>
              <CSVLink data={logs} separator={';'} filename={'Auditoria.csv'}>
                Baixar arquivo
              </CSVLink>
            </Button>
          </Botao>
        )}
      </Botao>
      <NewSnackbar
        open={open}
        setOpen={setOpen}
        severity={severity}
        message={feedbackMessage}
      />
    </form>
  )
}

const Button = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    border: none;
    background-color: ${theme.colors.powerbi.secondary};
    color: #000000e0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    border-radius: 1000px;
    cursor: pointer;
    min-width: 180px;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &.marginTop {
      margin-top: 40px;
    }
  `}
`

export default Auditoria

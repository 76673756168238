import React, { ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CREATE_SERVICE,
  GET_SERVICES,
  PAUSE_SERVICE,
  RESUME_SERVICE
} from './API/Embedded'

interface ContextValue {
  loading: boolean
  errorMessage: string
  open: boolean
  setOpen: any
  severity: string
  setSeverity: any
  feedbackMessage: string
  setFeedbackMessage: any
  services: Array<any>
  service: any
  createService: (token: string | null, formData: any) => void
  resumeService: (token: string | null, formData: any) => void
  pauseService: (token: string | null, formData: any) => void
  listAll: (token: string | null, increment: boolean) => void
  setEmbeddedValue: (value: any) => void
}

interface Props {
  children: ReactNode
}

export const EmbeddedContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
)

export const EmbeddedContextProvider = ({ children }: Props) => {
  const [services, setServices] = React.useState([])
  const [service, setService] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const [totalPagesUsers, setTotalPageUsers] = React.useState<number>(1)
  const [pageUsers, setPageUsers] = React.useState<number>(0)

  //snackbar
  const [open, setOpen] = React.useState<boolean>(false)
  const [severity, setSeverity] = React.useState<string>('')
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('')

  async function createService(token: string | null, formData: any) {
    try {
      setLoading(true)
      const { url, options } = CREATE_SERVICE(token, formData)
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.erro)
      }
    } catch (err: any) {
      console.log(err)
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function resumeService(token: string | null, formData: any) {
    try {
      setLoading(true)
      const { url, options } = RESUME_SERVICE(token, formData)
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.erro)
      }
    } catch (err: any) {
      console.log(err)
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function pauseService(token: string | null, formData: any) {
    try {
      setLoading(true)
      const { url, options } = PAUSE_SERVICE(token, formData)
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.erro)
      }
    } catch (err: any) {
      console.log(err)
      setSeverity('error')
      setFeedbackMessage(String(err))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  async function listAll(token: string | null, increment: boolean) {
    try {
      setLoading(true)
      const t = token ? token : window.localStorage.getItem('token')
      if (increment) {
        setPageUsers((pageUsers ? pageUsers : 0) + 1)
      } else if (!increment) {
        setPageUsers(0)
      }

      //Não deve atualizar caso o numero de páginas seja maior que o numero de total retornado pelo server
      if (totalPagesUsers < pageUsers) {
        setPageUsers(totalPagesUsers)
      }

      const { url, options } = GET_SERVICES(t, pageUsers)
      const response = await fetch(url, options)
      if (!response.ok) {
        const body = await response.json()
        throw new Error(body.erro)
      }

      const json = await response.json()
      const { content, totalPages } = json
      setTotalPageUsers(totalPages)
      setServices(pageUsers == 0 ? content : services.concat(content))
    } catch (error) {
      setErrorMessage(String(error))
      setSeverity('error')
      setFeedbackMessage(String(error))
      setOpen(true)
    } finally {
      setLoading(false)
    }
  }

  function setEmbeddedValue(value: any) {
    setService(value)
  }

  const value = {
    listAll,
    createService,
    resumeService,
    pauseService,
    setEmbeddedValue,
    services,
    service,
    loading,
    open,
    setOpen,
    severity,
    setSeverity,
    feedbackMessage,
    setFeedbackMessage,
    errorMessage
  }

  return (
    <EmbeddedContext.Provider value={value}>
      {children}
    </EmbeddedContext.Provider>
  )
}

export function useEmbeddedContext() {
  const context = useContext(EmbeddedContext)

  if (typeof context === 'undefined') {
    throw new Error('useEmbeddedContext must be used within an EmbeddedContext')
  }

  return context
}

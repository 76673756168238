import { useUserContext } from 'components/Data/UserContext'
import Content from './Content'
import Drawer from './Drawer'
import * as S from './styles'

const Index = () => {
  const { userData, currentUser } = useUserContext()
  return (
    <S.Wrapper>
      <Drawer />
      <Content />
    </S.Wrapper>
  )
}

export default Index

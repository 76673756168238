import { createGlobalStyle, css } from 'styled-components'
import px2vw from '../utils/px2vw'

const GlobalStyles = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -webkit-osx-font-smoothing: grayscale


    &::before, &::after {
      box-sizing: inherit;
    }
    
  }

  :root {
    font-size: ${px2vw(24)};

    @media (max-width: 575.98px) {
      font-size: ${px2vw(28)};
    }

    @media (min-width: 576px) {
      font-size: ${px2vw(24)};
    }

    @media (min-width: 768px) {
      font-size: ${px2vw(18)};
    }

    @media (min-width: 1024px) {
      font-size: ${px2vw(16)};
    }
  }
  
  ${({ theme }) => css`
    a {
      text-decoration: none;
      color: ${theme.colors.gray};
    }

    body {
      background-color: #fff;
      font-size: ${theme.font.sizes.body};
      font-family: ${theme.font.family};
    }

    h1 {
      font-size: ${theme.font.sizes.h1};
      line-height: 1.8rem;
      font-weight: ${theme.font.semiBold};
      padding-bottom: 32px;
      color: ${theme.colors.black};
    }

    h2 {
      font-size: ${theme.font.sizes.h2};
      line-height: 1.5rem;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.black};
    }

    h3 {
      font-size: ${theme.font.sizes.h3};
      line-height: 3rem;
      font-weight: ${theme.font.semiBold};
    }

    p {
      font-size: ${theme.font.sizes.body};
      line-height: 1rem;
      font-weight: ${theme.font.normal};
      color: ${theme.colors.gray};
    }

    .pink {
      display: inline-block;
      font-size: 0.75rem;
      line-height: 1rem;
      color: ${theme.colors.powerbi.ascent};
      text-transform: uppercase;
      font-weight: ${theme.font.semiBold};
      margin-bottom: 16px;
    }

    .back {
      display: flex;
      align-items: center;
      font-size: 1rem;
      color: ${theme.colors.gray};
      font-weight: ${theme.font.semiBold};
      margin-bottom: 42px;
    }

    .drawerPaper {
      background-color: #fafafa !important;
      border-right: none !important;
      padding: 15px;
      box-sizing: border-box !important;
      position: relative;
      overflow-y: hidden !important;
    }

    .drawerClosed .drawerPaper {
      padding: 15px 8px;
    }
  `}
`

export default GlobalStyles

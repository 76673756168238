import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import { ReactComponent as IconEdit } from '../../../../assets/imgs/mai-ic-edit.svg'
import { ReactComponent as IconDelete } from '../../../../assets/imgs/mai-ic-trash.svg'
import { useWorkspaceContext } from 'components/Data/WorkspaceContext'
import { useUserContext } from 'components/Data/UserContext'
import NewSnackbar from 'components/NewSnackbar'
import { UserProps } from 'components/Data/Types/UserType'
import NewAlertDialog from 'components/NewAlertDialog'
import { API_URL } from '../../../Data/API/api'
import { useIndicatorContext } from 'components/Data/IndicatorsContext'
import { IndicatorProps } from 'components/Data/Types/IndicatorType'
import { useAuditoriaContext } from 'components/Data/AuditoriaContext'
import { useEmbeddedContext } from 'components/Data/EmbeddedContext'

interface Props {
  key?: number
  title: string
  active: boolean
  description: string
  icon?: string
  idItem: number
  workspace?: any
  usuario?: UserProps | undefined
  indicator?: IndicatorProps | undefined
  embedded?: any | null | undefined
}

const ItemList: React.FC<Props> = ({
  title,
  active,
  description,
  icon,
  idItem,
  usuario,
  workspace,
  indicator,
  embedded
}) => {
  const { id } = useParams()
  const {
    startWorkspaceEdit,
    deleteWorkspace,
    severity,
    feedbackMessage,
    open,
    setOpen
  } = useWorkspaceContext()

  const {
    setIndicator: setIndicatorData,
    createIndicator,
    editIndicator,
    deleteIndicator,
    severity: tipoIndicador,
    feedbackMessage: msgIndicador,
    open: abertoIndicador,
    setOpen: setAbertoIndicador
  } = useIndicatorContext()

  const {
    severity: tipoAuditoria,
    feedbackMessage: msgAuditoria,
    open: abertoAuditoria,
    setOpen: setAbertoAuditoria
  } = useAuditoriaContext()

  const {
    severity: tipoEmbedded,
    feedbackMessage: msgEmbedded,
    open: abertoEmbedded,
    setOpen: setAbertoEmbedded
  } = useEmbeddedContext()

  const navigate = useNavigate()
  const {
    token,
    atualizarUsuario,
    deleteUser,
    severity: tipo,
    feedbackMessage: msg,
    open: aberto,
    setOpen: setAberto
  } = useUserContext()

  const { service, setEmbeddedValue } = useEmbeddedContext()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const handleClickEdit = (tipo: string, id: number, value: any | null) => {
    if (tipo === 'usuarios') {
      atualizarUsuario(usuario)
      navigate(`criarUsuario`)
    } else if (tipo === 'relatorios') {
      startWorkspaceEdit(workspace)
      navigate('editarRelatorio')
    } else if (tipo === 'indicadores') {
      setIndicatorData(indicator)
      navigate('editarIndicador')
    } else if (tipo === 'embedded') {
      setEmbeddedValue(value)
      navigate('managerEmbedded')
    }
  }

  // Aqui só abre a msg de confirmação para remocao
  function handleDelete() {
    if (workspace !== undefined) {
      setIsDialogOpen(true)
    } else if (usuario) {
      setIsDialogOpen(true)
    } else if (indicator) {
      setIsDialogOpen(true)
    }
  }

  //aqui de fato remove
  const handleDeleteObject = (tipo: string) => {
    if (tipo === 'usuarios') {
      deleteUser(token, idItem)
    } else if (tipo === 'relatorios') {
      deleteWorkspace(token, idItem)
    } else if (tipo === 'indicadores') {
      deleteIndicator(token, idItem)
    }
  }

  return (
    <>
      <Item>
        <ItemInfos>
          {icon && (
            <div>
              <IconList>
                <Image src={API_URL + icon} alt={title} />
              </IconList>
            </div>
          )}
          <div>
            {/* Nome do Usuario ou .... */}
            <Name>{title}</Name>
            <Description>
              {description !== undefined && description.includes('2021-')
                ? `Criado em ${description.slice(0, 10)}`
                : description}
            </Description>
          </div>
          <div>
            <Active className={active ? 'active' : 'inactive'}>
              {active ? 'Ativo' : 'Desativado'}
            </Active>
          </div>
        </ItemInfos>
        {id !== 'auditoria' && (
          <WrapperIcons>
            <Icon
              aria-label="Editar"
              title="Editar"
              onClick={() =>
                handleClickEdit(id, 0, id === 'embedded' ? embedded : null)
              }
            >
              <IconEdit />
            </Icon>
            <Icon aria-label="Deletar" title="Deletar" onClick={handleDelete}>
              <IconDelete />
            </Icon>
          </WrapperIcons>
        )}
      </Item>

      <NewAlertDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        setDeleteConfirm={setDeleteConfirm}
        funcaoAposConfirmar={() => handleDeleteObject(id)}
        message={'Você tem certeza que deseja deletar esse item?'}
      />

      {id === 'relatorios' && (
        <NewSnackbar
          open={open}
          setOpen={setOpen}
          severity={severity}
          message={feedbackMessage}
        />
      )}

      {id === 'usuarios' && (
        <NewSnackbar
          open={aberto}
          setOpen={setAberto}
          severity={tipo}
          message={msg}
        />
      )}

      {id === 'indicadores' && (
        <NewSnackbar
          open={abertoIndicador}
          setOpen={setAbertoIndicador}
          severity={tipoIndicador}
          message={msgIndicador}
        />
      )}

      {id === 'auditoria' && (
        <NewSnackbar
          open={abertoAuditoria}
          setOpen={setAbertoAuditoria}
          severity={tipoAuditoria}
          message={msgAuditoria}
        />
      )}

      {id === 'embedded' && (
        <NewSnackbar
          open={abertoEmbedded}
          setOpen={setAbertoEmbedded}
          severity={tipoEmbedded}
          message={msgEmbedded}
        />
      )}
    </>
  )
}

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 16px;
  margin-bottom: 16px;

  & > div {
    height: 48px;
  }
`
const ItemInfos = styled.div`
  display: flex;
  align-items: center;
`
const Name = styled.h2`
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
  color: #000;
`
const Description = styled.p`
  font-size: 1rem;
  line-height: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
`
const Active = styled.span`
  display: inline-block;
  margin-left: 24px;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: default;

  &.active {
    color: #309c3f;
    background-color: rgba(56, 180, 73, 0.16);
  }
  &.inactive {
    color: #db3c31;
    background-color: #f4433629;
  }
`
const WrapperIcons = styled.div`
  display: flex;
  align-items: center;
`
const Icon = styled.span`
  display: inline-block;
  cursor: pointer;
  & > svg > path {
    transition: all 0.3s;
  }
  &:first-of-type {
    margin-right: 16px;
  }
  &:hover > svg > path {
    fill-opacity: 0.8;
  }
`
const IconList = styled.span`
  display: inline-block;
  margin-right: 24px;
  & > svg > path {
    fill-opacity: 0.56;
  }
`
const Image = styled.img`
  display: block;
  max-width: 24px;
  max-height: 24px;
`
export default ItemList

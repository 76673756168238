import { makeStyles } from '@material-ui/styles'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField
} from '@mui/material'
import { useAppContext } from 'components/Data/AppContext'
import { useUserContext } from 'components/Data/UserContext'
import MaidaBtn from 'components/MaidaBtn'
import NewSnackbar from 'components/NewSnackbar'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import ContainerLogin from '../Login/ContainerLogin'
import IconEmail from '../Login/IconEmail'
import {
  Container,
  Banner,
  Logo,
  ContainerDireita,
  LoginContainer,
  Header
} from '../Login/styles'
import { Botao, ErrorSenha } from './Configurations/Users/CreateUser'
import * as S from './styles'
import styled, { css } from 'styled-components'

const useStyles = makeStyles({
  texto: {
    fontSize: '16px'
  },
  input: {
    width: '50%',
    paddingBottom: '24px'
  }
})

type UserAPI = {
  name: string
  email: string
  password: string
  role: string
  workspaces: Array<string>
  active: boolean
  microsoftEmail: string
  googleEmail: string
}

const EsqueciSenha = () => {
  const classes = useStyles()
  const [email, setEmail] = React.useState('')
  const [emailError, setEmailError] = React.useState<string | null>()

  const { error, loading, forgetPassword } = useUserContext()

  async function handleSubmit(e) {
    e.preventDefault()
    if (validateEmail(email)) {
      const res = await forgetPassword(email)
      alert(res)
    }
  }

  const validateEmail = (valor) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (valor.length === 0) {
      setEmailError('Preencha um valor')
      return false
    } else if (!regex.test(valor)) {
      setEmailError('Digite um e-mail válido')
      return false
    } else {
      setEmailError(null)
      return true
    }
  }

  const handleEmailBlur = ({ target }) => {
    validateEmail(target.value)
  }

  const handleEmailChange = ({ target }) => {
    if (emailError) validateEmail(target.value)
    setEmail(target.value)
  }

  return (
    <Container>
      <Banner>
        <Logo />
      </Banner>
      <ContainerDireita>
        <LoginContainer>
          <Header>
            <h1>Recuperar Senha</h1>
          </Header>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: '14px'
            }}
          >
            <form
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              noValidate
              onSubmit={handleSubmit}
            >
              <div>
                <InputBox>
                  <IconEmail />
                  <Input
                    onBlur={handleEmailBlur}
                    onChange={handleEmailChange}
                    placeholder="seuEmail@dominio.com"
                    className={emailError ? '' : 'marginBottom'}
                  />
                </InputBox>
                {emailError && (
                  <Error style={{ marginBottom: '16px' }}>{emailError}</Error>
                )}
              </div>

              {error && (
                <p
                  style={{ marginTop: '10px', color: 'red', fontSize: '14px' }}
                >
                  {error}
                </p>
              )}

              {loading ? (
                <MaidaBtn
                  text="Carregando..."
                  type="submit"
                  disabled={true}
                  className="marginTop"
                />
              ) : (
                <MaidaBtn
                  text="Entrar"
                  type="submit"
                  disabled={!email}
                  className="marginTop"
                />
              )}
            </form>
          </div>
        </LoginContainer>
      </ContainerDireita>
    </Container>
  )
}

const InputBox = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`
const Error = styled.p`
  font-size: 14px;
  padding-top: 6px;
  color: red;
`

const Input = styled.input`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: 12px 20px 12px 56px;
    border: 2px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colors.black};
    font-family: 'Open Sans', sans-serif;

    &:focus {
      border: 2px solid ${theme.colors.powerbi.primary};
      outline: none;
    }
    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.56);
    }

    &.marginBottom {
      margin-bottom: 16px;
    }
  `}
`

const BackButton = styled.span`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: -90px;
  left: -10px;
`

export default EsqueciSenha

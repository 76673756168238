const IconAttachment = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90001 4.40001C7.00001 2.30001 10.5 2.30001 12.7 4.40001L20.1 11.8C21.7 13.4 21.7 15.9 20.1 17.5C18.5 19.1 16 19.1 14.4 17.5L8.40001 11.5C7.40001 10.5 7.40001 8.90002 8.40001 8.00002C9.40001 7.00002 11 7.00002 11.9 8.00002L16.5 12.6C16.9 13 16.9 13.6 16.5 14C16.1 14.4 15.5 14.4 15.1 14L10.4 9.30002C10 8.90002 9.30001 9.60002 9.70002 10L15.8 16.1C16.6 16.9 17.9 16.9 18.6 16.1C19.3 15.3 19.4 14 18.6 13.3L11.2 5.90001C9.80001 4.50001 7.60002 4.50001 6.30002 5.90001C5.00002 7.30001 4.90002 9.50002 6.30002 10.8L12.3 16.8C12.7 17.2 12.7 17.8 12.3 18.2C11.9 18.6 11.3 18.6 10.9 18.2L4.90001 12.2C2.80001 10.1 2.80001 6.60001 4.90001 4.40001Z"
        fill="blue"
      />
    </svg>
  )
}

export default IconAttachment

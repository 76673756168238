import React from 'react'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { useAppContext } from '../../../Data/AppContext'

const Search = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
  transition: 'all 0.3s',
  paddingBottom: '3px',
  '&:hover': {
    borderColor: 'rgba(0, 0, 0, 0.6)'
  }
}))

const SearchIconWrapper = styled('div')(() => ({
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#FF0073'
}))

const StyledInputBase = styled(InputBase)(() => ({
  flex: '1',
  '& .MuiInputBase-input::placeholder': {
    opacity: '1',
    color: 'rgba(0, 0, 0, 0.56)'
  },
  '& .MuiInputBase-input': {
    marginLeft: '40px',
    fontFamily: 'Open Sans, sans-serif',
    color: 'rgba(0, 0, 0, 0.88)',
    opacity: '1'
  }
}))

const SearchBar = (texto) => {
  const { filterSearch, setFilter } = useAppContext()
  const [text, setText] = React.useState<string>(texto)

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Digite a sua busca aqui"
        inputProps={{ 'aria-label': 'Pesquisar' }}
        value={filterSearch}
        onChange={(e) => {
          setText(e.target.value)
          setFilter(e.target.value)
        }}
      />
    </Search>
  )
}

export default SearchBar

import React from 'react'

const NaoEncontrada = () => {
  return (
    <div>
      <h1>Página não encontrada</h1>
    </div>
  )
}

export default NaoEncontrada
